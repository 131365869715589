// @flow
// $FlowFixMe
import React, { useState, useEffect, useRef } from 'react';
import classnames from 'classnames';
import { withRouter } from 'react-router';
import { compose } from 'redux';
import { connect } from 'react-redux';

import CapacitorSkeletonIfLoading from '../capacitor/SkeletonIfLoading';
import DiscoverGroupSlider from './DiscoverGroupSlider';
import * as analyticsActions from '../../actions/analytics';

import InfiniteScroll from '../../vendor/InfiniteScroll';
import { selectIsInApp } from '../../selectors/client';
import styles from './DiscoverGroupList.css';
import { DISCOVER_GROUP_TYPE_LIVESTREAM_EVENT_GROUP } from '../../constants';
import CapacitorHeaderBar from '../capacitor/HeaderBar';

import type { DiscoverGroup, Hotlink } from '../../shapes/types';
import DiscoverHeroSlider from './DiscoverHeroSlider';
import Hotlinks from '../common/Hotlinks';
import {
  getSessionValueFromSessionStorage,
  isReload,
  saveLastStateInSessionStorage,
  SCROLL_CONTEXT_KEYS,
} from '../../utils/sessionStorage';
import DiscoverFooter from './DiscoverFooter';

declare var __CAPACITOR__: boolean;

const LoaderSkeleton = () => (
  <div className={styles.skeleton}>
    <div className={classnames('global-skeleton-item', styles.skeletonItem)} />
    <div className={classnames('global-skeleton-item', styles.skeletonItem)} />
    <div className={classnames('global-skeleton-item', styles.skeletonItem)} />
    <div className={classnames('global-skeleton-item', styles.skeletonItem)} />
  </div>
);

type OwnProps = {
  result: {
    title: string,
    subtitle: string,
    groups: Array<DiscoverGroup>,
    heroGroup?: DiscoverGroup,
    nonHeroGroups: Array<DiscoverGroup>,
  },
  slug?: string,
  trackingScreen: string,
  viewAllQueryParams?: Object,
  location: Object,
  isHomePage?: boolean,
  hasBanner?: boolean,
  hotlinkItems?: Array<Hotlink>,
};

type MapStateToProps = {
  isInApp: boolean,
};

type DispatchProps = {
  analyticsTrack: Function,
  trackPlayPausePressed: Function,
};

type Props = OwnProps & MapStateToProps & DispatchProps;

const DEFAULT_NUM_GROUPS_TO_LOAD = 5;

function DiscoverGroupList({
  result,
  isHomePage,
  hasBanner,
  hotlinkItems,
  trackingScreen,
  viewAllQueryParams,
  location,
  slug,
  isInApp,
  analyticsTrack,
  trackPlayPausePressed,
}: Props) {
  const { title, subtitle, groups, heroGroup, nonHeroGroups } = result;
  const [visibleGroupCount, setVisibleGroupCount] = useState(DEFAULT_NUM_GROUPS_TO_LOAD);
  // We need to use this to access the location before unmount
  const locationRef = useRef(location);

  useEffect(() => {
    locationRef.current = location;

    const sessionValue = getSessionValueFromSessionStorage(
      location,
      SCROLL_CONTEXT_KEYS.DISCOVER_GROUP_LIST
    );

    // if we are navigating back/forward without reloading the page, restore the last visible group count
    if (!isReload() && location.action === 'POP' && sessionValue && sessionValue.groupCount) {
      setVisibleGroupCount(sessionValue.groupCount);
      return;
    }

    if (location.hash) {
      // If we have a hash to navigate to, disable the entire infinite scroll by
      // showing all items. Otherwise we might not be able to navigate to the
      // element because it may be hidden.
      setVisibleGroupCount(groups.length);

      setTimeout(() => {
        const id = location.hash.replace('#', '');
        const targetGroup = document.getElementById(id);
        if (targetGroup) {
          targetGroup.scrollIntoView();
        }
      }, 200);
    }
  }, [location, groups.length]);

  useEffect(() => {
    return () => {
      saveLastStateInSessionStorage(
        locationRef.current,
        true,
        SCROLL_CONTEXT_KEYS.DISCOVER_GROUP_LIST,
        { groupCount: visibleGroupCount }
      );
    };
  }, [location, visibleGroupCount]);

  const loadMore = () => {
    setVisibleGroupCount(count =>
      count + DEFAULT_NUM_GROUPS_TO_LOAD <= groups.length
        ? count + DEFAULT_NUM_GROUPS_TO_LOAD
        : groups.length
    );
  };

  const hasMore = visibleGroupCount < groups.length;

  const onItemClick = ({ content }, groupId) => {
    analyticsTrack('Selected Content From Category', {
      id: content.id,
      type: content.type,
      category: groupId,
      pageName: trackingScreen,
      slug,
    });
  };

  const onItemBeforeTogglePlay = (item, playing, groupId) => {
    trackPlayPausePressed(
      {
        contextType: item.content.type,
        contextId: item.content.id,
        contextCategory: groupId,
      },
      playing
    );
  };

  if (!groups) {
    return null;
  }

  return (
    <CapacitorSkeletonIfLoading>
      <InfiniteScroll
        pageStart={0}
        loadMore={loadMore}
        hasMore={hasMore}
        threshold={100}
        loader={__CAPACITOR__ ? <LoaderSkeleton key="skeleton" /> : null}
        scrollableSelector=".content"
        useWindow={__CAPACITOR__ || isInApp}
        initialLoad={false}
      >
        {!hasBanner && __CAPACITOR__ && (
          <CapacitorHeaderBar withBackButton shareContentType="DiscoverGroupList" />
        )}
        {title && (
          <div className={hasBanner ? 'sr-only' : styles.header}>
            <h1 className="fz--beta">{title}</h1>
            {subtitle && <p>{subtitle}</p>}
          </div>
        )}
        {heroGroup && (
          <DiscoverHeroSlider
            items={heroGroup.items}
            onItemClick={onItemClick}
            beforeTogglePlay={onItemBeforeTogglePlay}
            isLivestreamEventGroup={heroGroup.type === DISCOVER_GROUP_TYPE_LIVESTREAM_EVENT_GROUP}
            groupId={heroGroup.id}
          />
        )}
        {hotlinkItems && (
          <div className={styles.hotlinks}>
            <Hotlinks items={hotlinkItems} trackingSource={trackingScreen} />
          </div>
        )}
        {nonHeroGroups.slice(0, visibleGroupCount).map((group, index) => {
          const isLastGroup = index === nonHeroGroups.length - 1;
          const nextGroupId = isLastGroup ? undefined : nonHeroGroups[index + 1].id;
          return (
            <DiscoverGroupSlider
              group={group}
              key={index}
              nextGroupId={nextGroupId}
              trackingScreen={trackingScreen}
              slug={slug}
              viewAllQueryParams={viewAllQueryParams}
              isHomePage={isHomePage}
              onItemClick={onItemClick}
              onItemBeforeTogglePlay={onItemBeforeTogglePlay}
            />
          );
        })}
        {isHomePage && !hasMore && <DiscoverFooter originPath={location.pathname} />}
      </InfiniteScroll>
    </CapacitorSkeletonIfLoading>
  );
}

function mapStateToProps(state: Object): MapStateToProps {
  return {
    isInApp: selectIsInApp(state),
  };
}

const dispatchProps: DispatchProps = {
  analyticsTrack: analyticsActions.track,
  trackPlayPausePressed: analyticsActions.trackPlayPausePressed,
};

export default compose(withRouter, connect(mapStateToProps, dispatchProps))(DiscoverGroupList);
