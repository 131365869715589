// @flow
import React from 'react';
import type { ChildrenArray, Element } from 'react';
import styles from './FacetGroupAllFacetsOverlay.css';
import OverlaySlideLeft from './OverlaySlideLeft';
import ResetActiveFacetsByGroupButton from '../facet/ResetActiveFacetsByGroupButton';

type OwnProps = {
  isVisible: boolean,
  hideModal: Function,
  title: Element<any> | string,
  children?: ChildrenArray<Element<any>>,
  selectedFacetIds: Array<number | string | boolean>,
  location: Object,
  type: string | null,
  onClickClear: Function,
};

type Props = OwnProps;

const FacetGroupAllFacetsOverlay = ({
  isVisible,
  hideModal,
  title,
  children,
  selectedFacetIds,
  location,
  type,
  onClickClear,
}: Props) => {
  const { pathname, query } = location;

  const ResetButton = () => {
    if (!type || !selectedFacetIds.length) {
      return null;
    }

    return (
      <ResetActiveFacetsByGroupButton
        type={type}
        pathname={pathname}
        query={query}
        onClickClear={onClickClear}
      />
    );
  };

  return (
    <OverlaySlideLeft
      isVisible={isVisible}
      onClose={hideModal}
      title={title}
      navigation={<ResetButton />}
      transparentOverlay
      data-test="facet.group.all-facets-overlay"
    >
      <div className={styles.overlay}>{children}</div>
    </OverlaySlideLeft>
  );
};

export default FacetGroupAllFacetsOverlay;
