import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';

import metaInjector from '../hoc/metaInjector';
import trackedViewComponent from '../hoc/trackedViewComponent';
import dataComponent from '../hoc/dataComponent';
import collectibleEntity, { collectibleEntityPropTypes } from '../hoc/collectibleEntity';

import { loadProfile } from '../actions/profile';

import { injectIntl, intlShape } from 'react-intl';
import { includes, isEmpty } from 'lodash';
import urlJoin from 'url-join';
import * as Shapes from '../shapes';

import RedirectInstruction from '../lib/routing/RedirectInstruction';
import { ApiError } from '../middleware/api';
import { formatGoogleMusicGroup } from '../lib/googleMusicActions';
import { DataFetcherLoadingContext } from '../lib/routing/DataFetcher';

import {
  selectArtistIdFromSlug,
  selectProfile,
  selectProfileImageAnnotation,
} from '../selectors/profile';

import {
  selectNextFilteredRecordingsCursor,
  selectPreviousFilteredRecordingsCursor,
} from '../selectors/recording';

import {
  selectNextFilteredWorksCursor,
  selectPreviousFilteredWorksCursor,
} from '../selectors/work';

import {
  selectNextFilteredAlbumsCursor,
  selectPreviousFilteredAlbumsCursor,
} from '../selectors/album';

import {
  selectAlbumsFilterParams,
  selectPlaylistsFilterParams,
  selectRecordingsFilterParams,
  selectWorksFilterParams,
} from '../selectors/filteredList';

import { ENTITY_TYPE_ARTIST, IMAGES_BASE } from '../constants';

import LoadingIndicator from '../components/common/LoadingIndicator';
import ProfileHead from '../components/profile/ProfileHead';
import ProfileNavigation from '../components/profile/ProfileNavigation';
import ProfileBanner from '../components/profile/ProfileBanner';
import CapacitorHeaderBar from '../components/capacitor/HeaderBar';
import { messages } from '../components/profile/messages';
import Head from '../components/chrome/Head';
import styles from './Profile.css';
import * as analyticsActions from '../actions/analytics';

function checkShouldShowAboutTab(profile) {
  return (
    profile.numEssentialsPlaylists > 0 ||
    profile.numConcerts > 0 ||
    profile.numLatestReleases > 0 ||
    profile.numRecommendedAlbums > 0
  );
}
class Profile extends PureComponent {
  static propTypes = {
    profile: Shapes.Profile.isRequired,
    profileIsComposer: PropTypes.bool.isRequired,
    profileHasPlaylists: PropTypes.bool.isRequired,
    children: PropTypes.object.isRequired,
    intl: intlShape.isRequired,
    imageAnnotation: PropTypes.object,
    currentCursor: PropTypes.string,
    nextCursor: PropTypes.string,
    prevCursor: PropTypes.string,
    ...collectibleEntityPropTypes,
  };

  static onDataFetch(store, params, location) {
    const state = store.getState();
    const slugOrArtistId = params.slug;
    const profile = selectProfile(state, slugOrArtistId);
    const slugUrl = profile.slug;
    const pathname = location.pathname;
    const showAlbumsTab = profile.numAlbumsAsParticipant > 0;
    const showPlaylistsTab = profile.numPlaylists > 0;
    const showAboutTab = checkShouldShowAboutTab(profile);
    const isArtistId = /^\d+$/.test(slugOrArtistId);
    const defaultRoute = `profiles/${slugUrl}`;
    const isDefaultRoute =
      pathname === defaultRoute ||
      pathname === `${defaultRoute}/` ||
      pathname === `/${defaultRoute}`;
    const isNamespaced = includes(pathname, 'profiles/');
    if (isEmpty(profile)) {
      // selectProfile returns {} for non loaded profiles
      return Promise.reject(new ApiError(404, 'Not found', 'Profile not found'));
    }

    if (!isNamespaced) {
      return Promise.reject(new RedirectInstruction(urlJoin('/', 'profiles', pathname), true));
    }

    if (isArtistId || isDefaultRoute) {
      if (showAboutTab) {
        return Promise.reject(new RedirectInstruction(`/profiles/${slugUrl}/about`, true));
      }
      if (showAlbumsTab) {
        return Promise.reject(new RedirectInstruction(`/profiles/${slugUrl}/albums`, true));
      }
      if (showPlaylistsTab) {
        return Promise.reject(new RedirectInstruction(`/profiles/${slugUrl}/playlists`, true));
      }
      return Promise.reject(new RedirectInstruction(`/profiles/${slugUrl}/recordings`, true));
    }

    return null;
  }

  render() {
    const {
      profile,
      profileIsComposer,
      profileHasPlaylists,
      children,
      intl,
      imageAnnotation,
      currentCursor,
      nextCursor,
      prevCursor,
      isInCollection,
      shouldShowAboutTab,
      toggleIsInCollection,
    } = this.props;
    const { locale } = intl;
    const displayLoadingIndicator = this.context && __CAPACITOR__;

    return (
      <div className="u-page-container">
        <Head
          googleMusicAction={{
            type: 'application/ld+json',
            innerHTML: `${formatGoogleMusicGroup(profile, locale)}`,
          }}
          slug={profile.slug}
          currentCursor={currentCursor}
          nextCursor={nextCursor}
          prevCursor={prevCursor}
        />
        {__CAPACITOR__ && (
          <CapacitorHeaderBar
            shareContentType="Profile"
            title={profile.name}
            onlyShowTitleOnScroll
            isInCollection={isInCollection}
            toggleIsInCollection={toggleIsInCollection}
          />
        )}
        <ProfileBanner bannerImageUrl={profile.bannerImageUrl} />
        <ProfileHead
          profile={profile}
          imageAnnotation={imageAnnotation}
          isInCollection={isInCollection}
          toggleIsInCollection={toggleIsInCollection}
        />
        <ProfileNavigation
          slug={profile.slug}
          profileIsComposer={profileIsComposer}
          profileHasPlaylists={profileHasPlaylists}
          shouldShowAboutTab={shouldShowAboutTab}
        />
        <div className={styles.mainSectionWrapper}>
          {displayLoadingIndicator && (
            <div className={styles.loadingIndicator}>
              <LoadingIndicator isLoading />
            </div>
          )}
          <div>{children}</div>
        </div>
      </div>
    );
  }

  static contextType = DataFetcherLoadingContext;
}

function fetchData(store, { slug }) {
  return store.dispatch(loadProfile(slug));
}

function getRouteType(path) {
  if (includes(path, 'recordings')) {
    return 'recording';
  }

  if (includes(path, 'albums')) {
    return 'album';
  }

  if (includes(path, 'playlists')) {
    return 'playlist';
  }

  return 'work';
}

function getNextCursorSelector(path) {
  const routeType = getRouteType(path);

  switch (routeType) {
    case 'recording':
      return selectNextFilteredRecordingsCursor;
    case 'album':
      return selectNextFilteredAlbumsCursor;
    case 'work':
      return selectNextFilteredWorksCursor;
    default:
      return selectNextFilteredRecordingsCursor;
  }
}

function getPreviousCursorSelector(path) {
  const routeType = getRouteType(path);

  switch (routeType) {
    case 'recording':
      return selectPreviousFilteredRecordingsCursor;
    case 'album':
      return selectPreviousFilteredAlbumsCursor;
    case 'work':
      return selectPreviousFilteredWorksCursor;
    default:
      return selectPreviousFilteredRecordingsCursor;
  }
}

function getFilterParamsSelector(path) {
  const routeType = getRouteType(path);

  switch (routeType) {
    case 'recording':
      return selectRecordingsFilterParams;
    case 'album':
      return selectAlbumsFilterParams;
    case 'work':
      return selectWorksFilterParams;
    default:
      return selectPlaylistsFilterParams;
  }
}

function mapStateToProps(state, ownProps) {
  const { params, location, children } = ownProps;
  const { query, pathname } = location;
  if (!query) {
    // quick exit
    return {};
  }
  const { cursor, slug: slugOrArtistId } = params;
  const profile = selectProfile(state, slugOrArtistId);
  const profileIsComposer = !!profile.numRecordingsAsComposer;
  const profileHasPlaylists = profile.numPlaylists > 0;

  const filterParams = getFilterParamsSelector(query || {}, profile.id);
  const selectNextCursor = getNextCursorSelector(pathname);
  const selectPrevCursor = getPreviousCursorSelector(pathname);
  return {
    profile,
    pathname,
    profileIsComposer,
    profileHasPlaylists,
    shouldShowAboutTab: checkShouldShowAboutTab(profile),

    imageAnnotation: selectProfileImageAnnotation(state, slugOrArtistId),
    currentCursor: cursor,
    nextCursor: selectNextCursor(state, filterParams),
    prevCursor: selectPrevCursor(state, filterParams),

    children,
  };
}

function getMetaInformation(props) {
  const { profile, intl } = props;
  const { formatMessage } = intl;
  return {
    'title': formatMessage(messages.profileMetaTitle, { name: profile.name }),
    'description': formatMessage(messages.profileMetaDescription, {
      name: profile.name,
    }),
    'keywords': formatMessage(messages.profileMetaKeywords, {
      name: profile.name,
    }),
    'og:image': `${IMAGES_BASE}artists/${props.profile.id}/main.jpg?auto=format&dpr=1&fit=fill&fill=blur&w=1200&h=630`,
    'og:title': formatMessage(messages.profileOgTitle, { name: profile.name }),
    'og:type': 'profile',
  };
}

const trackingProps = (state, params) => {
  return {
    contextType: 'Profile',
    contextId: selectArtistIdFromSlug(state, params.slug),
  };
};

function getCollectibleEntityDescription(props) {
  return {
    id: props.profile.id,
    trackingSource: 'Profile',
  };
}

export default compose(
  trackedViewComponent('Profile', trackingProps),
  dataComponent(fetchData),
  connect(mapStateToProps, {
    track: analyticsActions.track,
  }),
  injectIntl,
  metaInjector(getMetaInformation),
  collectibleEntity(ENTITY_TYPE_ARTIST, getCollectibleEntityDescription)
)(Profile);
