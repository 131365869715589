// @flow
import React from 'react';
import ReactModal from 'react-modal';
import { AnimatePresence, motion } from 'framer-motion';
import LoadingIndicator from '../common/LoadingIndicator';
import { DataFetcherLoadingContext } from '../../lib/routing/DataFetcher';

import styles from './Overlay.css';
import classNames from 'classnames';
import CapacitorRipple from '../capacitor/Ripple';
import IconLabel from '../util/IconLabel';

declare var __CAPACITOR__: boolean;

type OwnProps = {
  'children': React$Element<any>,
  'isVisible': boolean,
  'onClose': Function,
  'title'?: React$Element<any> | string,
  'navigation'?: React$Element<any>,
  'data-test'?: string,
  'transparentOverlay'?: boolean,
  'animationDirection': 'left' | 'up',
};

const Overlay = ({
  children,
  isVisible,
  onClose,
  title,
  navigation,
  'data-test': dataTest,
  transparentOverlay,
  animationDirection,
}: OwnProps) => {
  // $FlowFixMe
  const isLoading = React.useContext(DataFetcherLoadingContext);

  const getInitialTransition = () => {
    switch (animationDirection) {
      case 'left':
        return { opacity: 0, x: '100%' };
      case 'up':
        return { opacity: 0, y: '100%' };
      default:
        return {};
    }
  };

  const getFinalTransition = () => {
    switch (animationDirection) {
      case 'left':
        return { opacity: 1, x: '0%' };
      case 'up':
        return { opacity: 1, y: '0%' };
      default:
        return {};
    }
  };

  const CloseBtn = () => {
    switch (animationDirection) {
      case 'left':
        return <IconLabel name="chevron-left" title="Back" />;
      case 'up':
        return <IconLabel name="close" title="Close" />;
      default:
        return null;
    }
  };

  return (
    <AnimatePresence>
      {isVisible && (
        <ReactModal
          className={classNames(styles.modal, { 'is-capacitor': __CAPACITOR__ })}
          portalClassName={styles.portal}
          bodyOpenClassName={styles.noScroll}
          overlayClassName={transparentOverlay ? styles.overlayTransparent : styles.overlay}
          isOpen={isVisible}
          onClose={onClose}
          onRequestClose={onClose}
        >
          <motion.div
            initial={getInitialTransition()}
            animate={getFinalTransition()}
            exit={getInitialTransition()}
            transition={{ duration: 0.3 }}
            className={styles.component}
            data-test={dataTest}
          >
            <div className={styles.headerBar}>
              <button onClick={onClose} className={styles.closeBtn} data-test="overlay.close-btn">
                <CapacitorRipple version="center" />
                <CloseBtn />
              </button>
              {title && (
                <h1 className={classNames('fz--gamma c-text--is-truncated', styles.title)}>
                  {title}
                </h1>
              )}
              {navigation && <div className={styles.nav}>{navigation}</div>}
            </div>
            <section className={styles.contentWrapper}>{children}</section>
            {__CAPACITOR__ && isLoading && (
              <div className={styles.loader}>
                <LoadingIndicator isLoading />
              </div>
            )}
          </motion.div>
        </ReactModal>
      )}
    </AnimatePresence>
  );
};

export default Overlay;
