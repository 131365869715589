import React from 'react';
import { FormattedMessage } from 'react-intl';
import Button from './Button';

export default props => (
  <Button
    {...props}
    link="https://docs.google.com/forms/d/e/1FAIpQLSeWpdAXcbxSaib4hDYo8jf6oTeqe_S6FJv-lUhm1i6ii7Aosw/viewform"
  >
    <FormattedMessage id="landing-page-us.notifyButton" defaultMessage="Notify me" />
  </Button>
);
