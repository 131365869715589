import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, injectIntl, intlShape } from 'react-intl';

import { CONSTANT_MESSAGES } from '../../constants';
import styles from './MainMenu.css';
import { getWebsiteUrlInLocale } from '../../lib/locale-utils';

const messages = defineMessages({
  giftCard: {
    id: 'menu.gift-card',
    defaultMessage: 'Gift Card',
  },
});

const GiftCardLink = ({ locale, intl, onClick }) => {
  const text = intl.formatMessage(messages.giftCard);
  const ariaLink = intl.formatMessage(CONSTANT_MESSAGES.externalLink, { linkText: text });

  return (
    <li>
      <a
        className={styles.link}
        href={getWebsiteUrlInLocale('/promotions/gift-card', locale)}
        target="_blank"
        rel="noopener noreferrer"
        aria-label={ariaLink}
        onClick={onClick}
        data-test="main-menu-gift-card-link"
      >
        {text}
      </a>
    </li>
  );
};

GiftCardLink.propTypes = {
  locale: PropTypes.string.isRequired,
  intl: intlShape,
  onClick: PropTypes.func.isRequired,
};

export default injectIntl(GiftCardLink);
