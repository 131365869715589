import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { FormattedMessage } from 'react-intl';

import * as collectionActions from '../../actions/collection';
import * as analyticsActions from '../../actions/analytics';
import * as uiActions from '../../actions/ui';

import {
  selectAlbumCollection,
  selectCollectionIds,
  selectNextAlbumCollectionCursor,
} from '../../selectors/collection';

import { selectFeatureFlag } from '../../selectors/features';

import stylesPlaylistCollection from './PlaylistCollection.css';

import dataComponent from '../../hoc/dataComponent';

import EmptyCollection from './EmptyCollection';
import CollectionArchiveHeader from './CollectionArchiveHeader';
import LimitedCollection from './LimitedCollection';
import PaginatedCollection from './PaginatedCollection';
import CollectionItem from './CollectionItem';

import { createGetLimitedCollection, createGetCollectionArchive } from './PlaylistCollection.js';

import CollectionAlbums from '../../components/messages/CollectionAlbums';

import { ENTITY_TYPE_ALBUM } from '../../constants';
import protectedComponent from '../../hoc/protectedComponent';

class AlbumCollection extends Component {
  static propTypes = {
    albumCollection: PropTypes.array,
    limitedCollection: PropTypes.array,
    albumIds: PropTypes.array,
    loadAlbumCollection: PropTypes.func.isRequired,
    showModal: PropTypes.func.isRequired,

    analyticsTrack: PropTypes.func.isRequired,
    trackPlayPausePressed: PropTypes.func.isRequired,

    nextCursor: PropTypes.string,
    isLoading: PropTypes.bool.isRequired,

    collectionAccessLimit: PropTypes.number,
    hasDisabledCollectionItems: PropTypes.bool.isRequired,
  };

  onItemClick = ({ content }) => {
    this.props.analyticsTrack('Selected Album From Collection', {
      id: content.id,
    });
  };

  renderAlbumDisplayItem = album => {
    return (
      <CollectionItem
        collectionItem={album}
        entityType={ENTITY_TYPE_ALBUM}
        onItemClick={this.onItemClick}
        beforeTogglePlay={this.beforeTogglePlay}
        key={album.id}
      />
    );
  };

  renderLimitedCollectionInfo = () => {
    return (
      <React.Fragment>
        <h1 className="fz--beta">
          <FormattedMessage
            id="collection.albums.limited-collection-albums"
            defaultMessage="Albums"
          />
        </h1>
        <p className="fz--delta">
          <FormattedMessage
            id="collection.albums.limit"
            defaultMessage="You can save up to { count } albums for free."
            values={{ count: this.props.collectionAccessLimit }}
          />
        </p>
      </React.Fragment>
    );
  };

  renderLimitedCollection() {
    return (
      <LimitedCollection
        listClassName={stylesPlaylistCollection.list}
        limitedCollection={this.props.limitedCollection}
        renderItem={this.renderAlbumDisplayItem}
        renderInfo={this.renderLimitedCollectionInfo}
        showModal={this.props.showModal}
      />
    );
  }

  renderArchiveInfo() {
    return (
      <CollectionArchiveHeader>
        <FormattedMessage
          id="collection.albums.previously-saved"
          defaultMessage="Your Previously Saved Albums"
        />
      </CollectionArchiveHeader>
    );
  }

  renderPaginatedCollection() {
    const { albumCollection, isLoading } = this.props;

    return (
      <PaginatedCollection
        loadMore={this.loadMore}
        hasMore={!!this.props.nextCursor}
        items={albumCollection}
        renderItem={this.renderAlbumDisplayItem}
        isLoading={isLoading}
        title={<CollectionAlbums />}
      />
    );
  }

  renderEmptyAlbumCollection() {
    return <EmptyCollection entityType={ENTITY_TYPE_ALBUM} />;
  }

  render() {
    const { albumIds, collectionAccessLimit, hasDisabledCollectionItems } = this.props;
    const hasAlbums = albumIds.length !== 0;
    const shouldRenderPaginatedCollection =
      hasAlbums && (hasDisabledCollectionItems || !collectionAccessLimit);
    return (
      <div>
        {!hasAlbums && this.renderEmptyAlbumCollection()}
        {collectionAccessLimit && this.renderLimitedCollection()}
        {hasDisabledCollectionItems && this.renderArchiveInfo()}
        {shouldRenderPaginatedCollection && this.renderPaginatedCollection()}
      </div>
    );
  }

  loadMore = () => {
    this.props.loadAlbumCollection(this.props.nextCursor);
  };

  beforeTogglePlay = (item, playing) => {
    this.props.trackPlayPausePressed({ contextType: 'favouriteAlbums' }, playing);
  };
}

const getLimitedCollection = createGetLimitedCollection();
const getCollectionArchive = createGetCollectionArchive();

function mapStateToProps(state) {
  const collectionAccessLimit = selectFeatureFlag(state, 'collections').access_limit;
  const albumCollection = selectAlbumCollection(state);
  const hasDisabledCollectionItems =
    collectionAccessLimit && collectionAccessLimit < albumCollection.length;

  return {
    isLoading: state.lists.collection.albums.loading,
    albumIds: selectCollectionIds(state).entities.albumIds,
    limitedCollection:
      collectionAccessLimit && getLimitedCollection(albumCollection, collectionAccessLimit),
    albumCollection: hasDisabledCollectionItems
      ? getCollectionArchive(albumCollection, collectionAccessLimit)
      : albumCollection,
    nextCursor: selectNextAlbumCollectionCursor(state),
    collectionAccessLimit,
    hasDisabledCollectionItems,
  };
}

function fetchData(store) {
  return store.dispatch(collectionActions.loadAlbumCollection());
}

export default compose(
  protectedComponent({ authenticatedOnly: true }),
  dataComponent(fetchData),
  connect(mapStateToProps, {
    loadAlbumCollection: collectionActions.loadAlbumCollection,
    trackPlayPausePressed: analyticsActions.trackPlayPausePressed,
    analyticsTrack: analyticsActions.track,
    showModal: uiActions.showModal,
  })
)(AlbumCollection);
