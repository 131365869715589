import React from 'react';
import PropTypes from 'prop-types';

import * as Shapes from '../../shapes';
import { PlayerPlugin } from '../../capacitor-connector';
import { CircularPlayButton } from '../common/CircularPlayButton';
import styles from './CapacitorVideoPlayer.css';

const CapacitorVideoPlayer = ({ imageUrl, video }) => {
  const handlePlay = () => {
    const { videoId, source } = video;
    PlayerPlugin.androidShouldPlayVideo({
      videoType: source,
      videoHash: videoId,
    });
  };

  return (
    <div className={styles.wrapper}>
      {imageUrl && <img src={imageUrl} className={styles.image} />}
      <div className={styles.tint} />
      <div className={styles.playButton}>
        <CircularPlayButton light onClick={handlePlay} />
      </div>
    </div>
  );
};

CapacitorVideoPlayer.propTypes = {
  video: Shapes.LivestreamEventVideo,
  imageUrl: PropTypes.string,
};

export default CapacitorVideoPlayer;
