import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { selectSubscriptionTrialDurationDays } from '../../selectors/subscriptionOffer';

class SubscribeFormattedMessage extends Component {
  static propTypes = {
    trialDuration: PropTypes.number.isRequired,
  };

  render() {
    const { trialDuration } = this.props;

    if (trialDuration > 0) {
      return (
        <FormattedMessage
          id="global.cta.trial-free-days"
          defaultMessage="Start my {trialDuration}-day free trial"
          values={{ trialDuration }}
        />
      );
    }

    return <FormattedMessage id="global.cta.trial-no" defaultMessage="Subscribe" />;
  }
}

function mapStateToProps(state) {
  return {
    trialDuration: selectSubscriptionTrialDurationDays(state),
  };
}

export default connect(mapStateToProps)(SubscribeFormattedMessage);
