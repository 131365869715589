// @flow
import React from 'react';
import Overlay from './Overlay';

type OwnProps = {
  'children': React$Element<any>,
  'isVisible': boolean,
  'onClose': Function,
  'title'?: React$Element<any> | string,
  'navigation'?: React$Element<any>,
  'data-test'?: string,
  'transparentOverlay'?: boolean,
};

const OverlaySlideUp = ({
  children,
  isVisible,
  onClose,
  title,
  navigation,
  'data-test': dataTest,
  transparentOverlay,
}: OwnProps) => {
  return (
    <Overlay
      isVisible={isVisible}
      onClose={onClose}
      title={title}
      navigation={navigation}
      data-test={dataTest}
      transparentOverlay={transparentOverlay}
      animationDirection="up"
    >
      {children}
    </Overlay>
  );
};

export default OverlaySlideUp;
