import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import {
  selectNextPersonalPlaylistCursor,
  selectPersonalPlaylistCollection,
} from '../../selectors/personalPlaylist';
import { selectFeatureFlag } from '../../selectors/features';
import dataComponent from '../../hoc/dataComponent';

import { personalPlaylistToPlayableItemPreview } from '../../lib/playableItemPreview';

import * as personalPlaylistActions from '../../actions/personalPlaylist';
import * as analyticsActions from '../../actions/analytics';
import * as uiActions from '../../actions/ui';

import PlaylistTrackCount from '../messages/PlaylistTrackCount';
import RedirectInstruction from '../../lib/routing/RedirectInstruction';
import CreateNewPlaylistButton from './CreateNewPlaylistButton';
import PaginatedCollection from './PaginatedCollection';
import PlayableItemPreview from '../common/PlayableItemPreview';

import styles from './PersonalPlaylistCollection.css';
import protectedComponent from '../../hoc/protectedComponent';

function getSubtitle(playlist) {
  return <PlaylistTrackCount trackCount={playlist.trackIds.length} />;
}

class PersonalPlaylistCollection extends Component {
  static propTypes = {
    playlists: PropTypes.array,
    analyticsTrack: PropTypes.func.isRequired,
    trackPlayPausePressed: PropTypes.func.isRequired,
    showAddToPlaylistModal: PropTypes.func.isRequired,
    loadPersonalPlaylists: PropTypes.func.isRequired,
    nextCursor: PropTypes.string,
    isLoading: PropTypes.bool.isRequired,
  };

  onItemClick = ({ content }) => {
    this.props.analyticsTrack('Selected Playlist from Collection', {
      id: content.id,
    });
  };

  onCreateNewPlaylistClick = () => {
    const { showAddToPlaylistModal } = this.props;

    showAddToPlaylistModal([]);
  };

  renderEmptyPlaylistCollection() {
    return (
      <p>
        <FormattedMessage
          id="personal-playlists.empty.intro"
          defaultMessage="You haven't created any playlists yet."
        />
      </p>
    );
  }

  renderPersonalPlaylistDisplayItem = playlist => {
    const playableItemPreview = personalPlaylistToPlayableItemPreview(playlist, getSubtitle);
    return (
      <li key={playlist.id} data-test="personal-playlist.item">
        <PlayableItemPreview
          item={playableItemPreview}
          onClick={this.onItemClick}
          beforeTogglePlay={this.beforeTogglePlay}
        />
      </li>
    );
  };

  render() {
    const { playlists, nextCursor, isLoading } = this.props;
    return (
      <React.Fragment>
        <div className={styles.header}>
          {playlists.length === 0 && this.renderEmptyPlaylistCollection()}
          <CreateNewPlaylistButton onClick={this.onCreateNewPlaylistClick} />
        </div>
        <PaginatedCollection
          loadMore={this.loadMore}
          hasMore={!!nextCursor}
          items={playlists}
          renderItem={this.renderPersonalPlaylistDisplayItem}
          isLoading={isLoading}
        />
      </React.Fragment>
    );
  }

  loadMore = () => {
    this.props.loadPersonalPlaylists(this.props.nextCursor);
  };

  beforeTogglePlay = (item, playing) => {
    this.props.trackPlayPausePressed({ contextType: 'personalPlaylists' }, playing);
  };
}

function fetchData(store) {
  if (selectFeatureFlag(store.getState(), 'user_playlists')) {
    return store.dispatch(personalPlaylistActions.loadPersonalPlaylists());
  }
  return Promise.reject(new RedirectInstruction('/collection/playlists'));
}

function mapStateToProps(state) {
  return {
    isLoading: state.lists.personalPlaylists.loading,
    nextCursor: selectNextPersonalPlaylistCursor(state),
    playlists: selectPersonalPlaylistCollection(state),
  };
}

export default compose(
  protectedComponent({ authenticatedOnly: true }),
  dataComponent(fetchData),
  connect(mapStateToProps, {
    loadPersonalPlaylists: personalPlaylistActions.loadPersonalPlaylists,
    trackPlayPausePressed: analyticsActions.trackPlayPausePressed,
    analyticsTrack: analyticsActions.track,
    showAddToPlaylistModal: uiActions.showAddToPlaylistModal,
  })
)(PersonalPlaylistCollection);
