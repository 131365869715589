import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter, locationShape } from 'react-router';
import Search from '../../search/QuickSearch';
import MainMenu from '../MainMenu';
import classnames from 'classnames';

import CtaButton from './CtaButton';
import ProgressBar from '../ProgressBar';

import { showModal as showModalActionCreator } from '../../../actions/ui';
import { logout as logoutActionCreator } from '../../../actions/auth';
import { track as analyticsActionCreator } from '../../../actions/analytics';
import * as routerActionCreators from 'react-router-redux';

import {
  selectUserCanSubscribe,
  selectUserIsAuthenticated,
  selectUserPlan,
  selectUserPlanSubscriptionType,
} from '../../../selectors/user';
import {
  selectIsWindows,
  selectChrome,
  selectIsMobile,
  selectIsMac,
  selectIsGeoBlockedCountry,
} from '../../../selectors/client';
import { selectCtaTrackingContext } from '../../../selectors/tracking';

import styles from './AppHeader.css';
import IconLabel from '../../util/IconLabel';

import {
  CHROMES_HIDE_SEARCH,
  CHROME_CLEAN,
  CHROMES_HIDE_MENU,
  ANONYMOUS_ONLY_PATHS,
} from '../../../constants.js';

import { unwrapRouteComponent } from '../../../utils/unwrapRouteComponent';

import AccountLogin from '../../messages/AccountLogin';
import SubscribeFormattedMessage from '../../messages/SubscribeFormattedMessage';
import A11ySkipLink from '../../a11y/A11ySkipLink';
import AccountJoin from '../../messages/AccountJoin';
import SubscribeNow from '../../messages/SubscribeNow';

const BACK = -1;
const FORWARD = 1;

class AppHeader extends PureComponent {
  static propTypes = {
    isAuthenticated: PropTypes.bool.isRequired,
    isMobile: PropTypes.bool.isRequired,
    isWindows: PropTypes.bool.isRequired,
    isMac: PropTypes.bool.isRequired,
    isGeoBlocked: PropTypes.bool.isRequired,
    showModal: PropTypes.func.isRequired,
    logout: PropTypes.func.isRequired,
    userCanSubscribe: PropTypes.bool.isRequired,
    userPlanSubscriptionType: PropTypes.string,
    userPlan: PropTypes.string,
    track: PropTypes.func.isRequired,
    back: PropTypes.func,
    forward: PropTypes.func,
    canGo: PropTypes.func,
    chrome: PropTypes.string,
    trackingContext: PropTypes.object,
    location: locationShape.isRequired,
    isJoinPage: PropTypes.bool.isRequired,
  };

  onSignupClick = event => {
    this.showSignupModal(event);
    this.props.track('Clicked Signup Button', this.props.trackingContext);
  };

  renderLoginLink(queryParams) {
    return (
      <Link
        to={`/login${queryParams}`}
        className={classnames(styles.link, styles.loginLink)}
        activeClassName="is-active"
        onClick={this.showLoginModal}
        data-test="header.show-login-modal-btn"
      >
        <AccountLogin />
      </Link>
    );
  }

  renderSignupLink(queryParams) {
    const signupLinkClasses = classnames('c-btn', 'c-btn--is-purple', styles.subscribeButton);

    const { isMobile } = this.props;
    if (isMobile) {
      return (
        <CtaButton
          noButtonClass
          activeClassName="is-active"
          className={signupLinkClasses}
          isMobile={isMobile}
          trackingData={{ from: 'Header' }}
        >
          <SubscribeFormattedMessage />
        </CtaButton>
      );
    }

    return (
      <Link
        to={`/join${queryParams}`}
        onClick={this.onSignupClick}
        className={signupLinkClasses}
        data-test="header.show-join-modal-btn"
      >
        <AccountJoin />
      </Link>
    );
  }

  renderLogoSvg() {
    return (
      <svg version="1.1" x="0px" y="0px" width="88px" height="104px" viewBox="0 0 88 104">
        <rect x="40" y="80" fill="#2F323D" width="48" height="24" />
        <polygon fill="#2F323D" points="0,0 0,80 64,40 " />
      </svg>
    );
  }

  renderLogoOrArrows() {
    if (__ELECTRON__) {
      const canGo = this.props.canGo;
      const classesIcon = classnames(styles.icon, 'c-icon--label-false');
      const arrowLeftClasses = classnames(classesIcon, {
        'is-active': canGo(BACK),
      });
      const arrowRightClasses = classnames(classesIcon, {
        'is-active': canGo(FORWARD),
      });
      return (
        <span className={styles.navArrows}>
          <button onClick={this.back}>
            <IconLabel name="chevron-left" className={arrowLeftClasses} title="Back" />
          </button>
          <button onClick={this.forward}>
            <IconLabel name="chevron-right" className={arrowRightClasses} title="Forward" />
          </button>
        </span>
      );
    }
    const classesLogo = classnames(styles.logo, 'u-hide-text');

    return this.props.isAuthenticated ? (
      <Link to="/discover" className={classesLogo} title="IDAGIO Discover">
        {this.renderLogoSvg()}
      </Link>
    ) : (
      <Link to="/" className={classesLogo} title="IDAGIO">
        {this.renderLogoSvg()}
      </Link>
    );
  }

  renderRightSideCtas() {
    const classesBtnSubscribe = classnames('c-btn', 'c-btn--is-purple', styles.subscribeButton);
    const { isAuthenticated, isGeoBlocked, userCanSubscribe, location, isJoinPage } = this.props;

    if (isGeoBlocked) {
      return null;
    }

    if (isAuthenticated) {
      if (!userCanSubscribe) {
        return null;
      }

      return (
        <button
          className={classesBtnSubscribe}
          onClick={this.showSubscribeModal}
          data-test="header.subscribe-btn"
        >
          <SubscribeNow />
        </button>
      );
    }

    let queryParams = '';
    if (location) {
      const to = `/${location.pathname}${location.search}`;
      queryParams = ANONYMOUS_ONLY_PATHS.includes(location.pathname)
        ? location.search
        : `?to=${encodeURIComponent(to)}`;
    }

    return (
      <div className={styles.rightLinks}>
        {!__ELECTRON__ && this.renderLoginLink(queryParams)}
        {!__ELECTRON__ && !isJoinPage && this.renderSignupLink(queryParams)}
      </div>
    );
  }

  render() {
    const { isAuthenticated, chrome, isWindows, isMac } = this.props;

    const headerClassNames = classnames(styles.component, {
      [styles.isWindows]: isWindows,
      [styles.isMacDesktop]: __ELECTRON__ && !isWindows,
      [styles.hasShadow]: !__ELECTRON__ || isAuthenticated,
    });

    const chromeHidesSearch = CHROMES_HIDE_SEARCH.includes(chrome);
    const chromeHidesRight = CHROME_CLEAN.includes(chrome);
    const chromeHidesMenu = CHROMES_HIDE_MENU.includes(chrome);

    return (
      <header className={headerClassNames} id="app-header">
        <A11ySkipLink target="#app-sidebar" />
        {__ELECTRON__ && (
          <div
            className={classnames(styles.draggable, styles.draggableTop)}
            id="app-header-draggable-top"
          />
        )}
        {__ELECTRON__ && isAuthenticated && (
          <div
            className={classnames(styles.draggable, styles.draggableLeft)}
            id="app-header-draggable-left"
          />
        )}
        <div className={styles.inner}>
          <div className={styles.left}>{this.renderLogoOrArrows()}</div>
          {!chromeHidesSearch && (
            <div className={styles.center}>
              <Search />
            </div>
          )}
          {!chromeHidesRight && (
            <div className={styles.right}>
              {this.renderRightSideCtas()}
              {!chromeHidesMenu && (
                <MainMenu
                  isAuthenticated={isAuthenticated}
                  logout={this.props.logout}
                  isMac={isMac}
                  isWindows={isWindows}
                />
              )}
            </div>
          )}
        </div>
        <ProgressBar />
      </header>
    );
  }

  showLoginModal = event => {
    event.preventDefault();
    this.props.showModal('LOGIN_MODAL', { trigger: 'appheader' });
  };

  showSubscribeModal = event => {
    event.preventDefault();
    const { showModal, userPlan } = this.props;
    showModal('SUBSCRIBE_MODAL', {
      trigger: 'appheader',
      plan: userPlan,
    });
  };

  showUpgradeModal = event => {
    event.preventDefault();
    const { showModal, userPlan, userPlanSubscriptionType } = this.props;
    showModal('UPGRADE_MODAL', {
      trigger: 'appheader',
      plan: userPlan,
      payment_gateway: userPlanSubscriptionType,
    });
  };

  showSignupModal = event => {
    event.preventDefault();
    this.props.showModal('SIGNUP_MODAL', { trigger: 'appheader' });
  };

  forward = () => {
    this.props.forward();
    document.activeElement.blur();
  };

  back = () => {
    this.props.back();
    document.activeElement.blur();
  };
}

function mapStateToProps(state, ownProps) {
  const { slug } = ownProps.params;
  const routeComponentName = unwrapRouteComponent(ownProps.routes).displayName;

  return {
    trackingToken: state.auth.trackingToken,
    canGo: ownProps.router.canGo,
    isAuthenticated: selectUserIsAuthenticated(state),
    isWindows: selectIsWindows(state),
    isMac: selectIsMac(state),
    isMobile: selectIsMobile(state),
    chrome: selectChrome(state),
    isGeoBlocked: selectIsGeoBlockedCountry(state),
    trackingContext: selectCtaTrackingContext(state, slug, routeComponentName),
    isJoinPage: ownProps.location.pathname === 'join',
    userCanSubscribe: selectUserCanSubscribe(state),
    userPlanSubscriptionType: selectUserPlanSubscriptionType(state),
    userPlan: selectUserPlan(state),
  };
}

const connectedComponent = connect(mapStateToProps, {
  showModal: showModalActionCreator,
  logout: logoutActionCreator,
  track: analyticsActionCreator,
  forward: routerActionCreators.goForward,
  back: routerActionCreators.goBack,
})(AppHeader);

export { AppHeader as ShallowAppHeader };

export default withRouter(connectedComponent);
