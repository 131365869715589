import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { defineMessages, injectIntl, intlShape } from 'react-intl';

import * as Shapes from '../../shapes';

import { ENTITY_TYPE_TRACK } from '../../constants';

import CollectionButton from '../common/CollectionButton';
import AddToPlaylistButton from '../common/AddToPlaylistButton';
import ShareButton from '../common/ShareButton';
import ComposerList from '../common/ComposerList';
import AuthorList from '../common/AuthorList';
import InterpreterList from '../common/InterpreterList';
import TitleWithPopularTitle from '../common/TitleWithPopularTitle';

import shareableEntity, { shareableEntityPropTypes } from '../../hoc/shareableEntity';
import collectibleEntity, { collectibleEntityPropTypes } from '../../hoc/collectibleEntity';

import { showAddToPlaylistModal } from '../../actions/ui';

import styles from './MoodTrackInfo.css';

const messages = defineMessages({
  share: {
    id: 'mood.track-info.share',
    defaultMessage: 'Share this piece',
  },
});

class MoodTrackInfo extends Component {
  static propTypes = {
    track: Shapes.Track.isRequired,
    intl: intlShape,
    moodId: PropTypes.string.isRequired,
    onAddToPlaylistButtonClick: PropTypes.func.isRequired,

    ...shareableEntityPropTypes,
    ...collectibleEntityPropTypes,
  };

  onAddToPlaylistClick = () => {
    this.props.onAddToPlaylistButtonClick(this.props.track.id);
  };

  render() {
    if (this.props.track) {
      const { track, isInCollection, intl } = this.props;
      const { toggleIsInCollection, showShareModal } = this.props;
      const { formatMessage } = intl;
      const { recording, piece } = track;
      const { work } = recording;
      const url = `/recordings/${recording.id}`;
      return (
        <div className={styles.info}>
          <div className={styles.composer}>
            <ComposerList authors={work.authors} hyperlinks />
          </div>
          <div className={styles.title}>
            <Link to={url}>
              <TitleWithPopularTitle {...work} />
              <br />
              <TitleWithPopularTitle {...piece} />
            </Link>
            <AuthorList authors={work.authors} className={styles.authorList} />
          </div>
          <div className={styles.interpreter}>
            <InterpreterList recording={recording} hyperlinks multiline />
          </div>
          <div className={styles.actions}>
            <CollectionButton onClick={toggleIsInCollection} active={isInCollection} />
            <AddToPlaylistButton onClick={this.onAddToPlaylistClick} />
            <ShareButton onClick={showShareModal} text={formatMessage(messages.share)} />
          </div>
        </div>
      );
    }

    return <div className="mood-track-info" />;
  }
}

function getCollectibleEntityDescription(props) {
  return {
    id: props.track.id,
    trackingSource: 'Mood',
  };
}

function getShareUrl(props) {
  return `/moods/${props.moodId}?trackId=${props.track.id}`;
}

function getShareTrackingContext(props) {
  return {
    sharedContentType: 'Track',
    sharedContentId: props.track.id,
    contextType: 'Mood',
    contentId: props.moodId,
  };
}

export default compose(
  injectIntl,
  shareableEntity(getShareUrl, getShareTrackingContext, ENTITY_TYPE_TRACK),
  collectibleEntity(ENTITY_TYPE_TRACK, getCollectibleEntityDescription),
  connect(null, {
    showAddToPlaylistModal,
  })
)(MoodTrackInfo);
