// @flow
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { head, includes } from 'lodash';
import qs from 'qs';
import { defineMessages, injectIntl } from 'react-intl';
import type { IntlShape } from 'react-intl';
import type { Recording, Work } from '../../shapes/types';
import classnames from 'classnames';

import * as uiActions from '../../actions/ui';
import * as analyticsActions from '../../actions/analytics';
import { createSelectSortedTopFacetsGroups } from '../../selectors/facet';

import List from '../util/List';
import FacetGroups from '../facet/FacetGroups';
import FacetFilterButton from '../facet/FacetFilterButton';

import SortSelect from '../common/SortSelect';
import FacetGroupAllFacets from '../facet/FacetGroupAllFacets';
import PlayButton from './PlayButton';
import PaginatedList from '../util/PaginatedList';
import LoadingIndicator from '../common/LoadingIndicator';

import styles from './FilteredList.css';
import mediaObjectGridStyles from '../util/MediaObjectGrid.css';
import { FACET_FILTER_TYPES } from '../../constants';
import LocalListSearch from './LocalListSearch';

const messages = defineMessages({
  playAllRecordings: {
    id: 'filtered-recording-list.play-all',
    defaultMessage: 'Play all recordings',
  },
  playSelectedRecordings: {
    id: 'filtered-recording-list.play-selected-recordings',
    defaultMessage: 'Play {count, plural, one {# recording} other {# recordings}}',
  },
  playAllAlbums: {
    id: 'filtered-album-list.play-all',
    defaultMessage: 'Play all albums',
  },
  playAllPlaylists: {
    id: 'filtered-playlists-list.play-all',
    defaultMessage: 'Play all playlists',
  },
  playSelectedAlbums: {
    id: 'filtered-album-list.play-selected-albums',
    defaultMessage: 'Play {count, plural, one {# album} other {# albums}}',
  },
});

declare var __CAPACITOR__: boolean;

type OwnProps = {
  activeFacets: Array<any>,
  facetOrder: Array<string>,
  loadAllFacets: Function,
  filterParams: Object,
  listIsEmpty: boolean,
  emptyListMessage: React$Element<any>,
  isLoading: boolean,
  isPlaying: boolean,
  shouldRenderPlayAllButton: boolean,
  items: Array<Recording> | Array<Work>,
  itemsCount: number,
  filterType: string,
  renderFilteredListItem: Function,
  gridLayout: boolean,
  sort: string,
  location: Object,
  loadMore: Function,
  topFacets: Array<any>,
  searchTerm?: string,
  ignoredFacets?: Array<string>,
  nextCursor?: string,
  currentCursor?: string,
  prevCursor?: string,
  togglePlayAll?: Function,
  contextId?: string,
  contextFacet?: string,
  shouldShowSearchBox?: boolean,
  onSearchChange?: Function,
  shouldRenderSortAndFilter?: boolean,
  handleMultipleComposers?: boolean,
};

type MapStateToProps = {
  hasActiveFacets: boolean,
  topFacets: Array<any>,
  shouldRenderSortAndFilter: boolean,
};

type DispatchProps = {
  showFacetGroupAllFacetsModal: Function,
  trackFilterReset: Function,
  trackFilterSelected: Function,
};

type Props = $Diff<OwnProps, { facetOrder: Array<string> }> &
  MapStateToProps &
  DispatchProps & { intl: IntlShape };

type State = {
  filterOpen: boolean,
  isLoadingMore: boolean,
};

class FilteredList extends React.Component<Props, State> {
  static contextTypes = {
    router: PropTypes.objectOf(PropTypes.any),
  };

  state = {
    filterOpen: false,
    isLoadingMore: false,
  };

  componentWillReceiveProps(nextProps) {
    const { filterType, contextId, contextFacet } = this.props;
    const { trackFilterReset, trackFilterSelected } = this.props;
    const filterWillBeApplied = nextProps.hasActiveFacets;
    const currentFacetsLength = this.props.activeFacets.length;
    const nextFacetsLength = nextProps.activeFacets.length;
    const filterWillChange = currentFacetsLength !== nextFacetsLength;
    const filterWillReset = !nextFacetsLength && currentFacetsLength;

    if (filterWillReset) {
      trackFilterReset(filterType);
    }

    if (filterWillBeApplied && filterWillChange) {
      const filters = nextProps.location.search;
      const filtersWithoutQuestionMark = filters.slice(1, filters.length);
      trackFilterSelected(filterType, {
        selectedId: contextId,
        facet: contextFacet,
        filters: filtersWithoutQuestionMark,
      });
    }

    if (this.props.isLoading && !nextProps.isLoading) {
      this.setState({
        isLoadingMore: false,
      });
    }
  }

  onSearchBlur = () => {
    const { currentCursor, location, searchTerm } = this.props;
    const query = { ...location.query, searchTerm };
    if (!searchTerm) {
      delete query.searchTerm;
    }

    let { pathname } = location;
    if (currentCursor) {
      // Always make sure to remove the cursor when changing sorting
      pathname = pathname.replace(`/pages/${currentCursor}`, '');
    }
    const target = pathname + '?' + qs.stringify(query);
    this.context.router.push(target);
  };

  onSearchChange = searchTerm => {
    if (this.props.onSearchChange) {
      this.props.onSearchChange(searchTerm);
    }
  };

  getPlayButtonText = () => {
    const { activeFacets, filterType, intl, hasActiveFacets } = this.props;
    const firstActiveFacet = head(activeFacets);
    if (firstActiveFacet && firstActiveFacet.facet.hits > 0 && hasActiveFacets) {
      const message =
        filterType === FACET_FILTER_TYPES.ALBUM
          ? messages.playSelectedAlbums
          : messages.playSelectedRecordings;
      return intl.formatMessage(message, { count: firstActiveFacet.facet.hits });
    }

    const filterTypeToMessageAssoc = {
      [FACET_FILTER_TYPES.ALBUM]: messages.playAllAlbums,
      [FACET_FILTER_TYPES.RECORDING]: messages.playAllRecordings,
      [FACET_FILTER_TYPES.PLAYLIST]: messages.playAllPlaylists,
    };

    const playAllMessage = filterTypeToMessageAssoc[filterType];
    return intl.formatMessage(playAllMessage);
  };

  renderToolbar = () => {
    const {
      sort,
      filterType,
      location,
      shouldRenderSortAndFilter,
      currentCursor,
      shouldShowSearchBox,
      hasActiveFacets,
      topFacets,
      items,
    } = this.props;

    const isFilterDisabled = topFacets.filter(this.shouldRenderFacetGroup).length < 1;

    return (
      <div className={styles.toolbar} data-test="filtered-list.toolbar">
        {shouldRenderSortAndFilter && (
          <React.Fragment>
            <div className={styles.btns}>
              <FacetFilterButton
                isActive={this.isFilterVisible() || hasActiveFacets}
                isDisabled={isFilterDisabled}
                toggleFacetFilterDrawer={() => {
                  if (this.isFilterVisible()) {
                    this.handleCloseFilter();
                  } else {
                    this.handleOpenFilter();
                  }
                }}
              />
              <SortSelect
                isDisabled={items.length < 2}
                optionsContext={filterType}
                sort={sort}
                location={location}
                currentCursor={currentCursor}
              />
            </div>
          </React.Fragment>
        )}
        {shouldShowSearchBox && (
          <LocalListSearch
            query={this.props.searchTerm}
            onSearchChange={this.onSearchChange}
            onSearchBlur={this.onSearchBlur}
            id="filtered-list-search-box-input"
            count={this.props.itemsCount}
            type={filterType}
            className={styles.searchBox}
          />
        )}
      </div>
    );
  };

  renderFilteredList() {
    const listClasses = classnames(styles.list, {
      [mediaObjectGridStyles.list]: this.props.gridLayout === true,
    });

    const { listIsEmpty, emptyListMessage, items, renderFilteredListItem } = this.props;
    if (listIsEmpty) {
      return emptyListMessage;
    }

    return <List className={listClasses} items={items} renderItem={renderFilteredListItem} />;
  }

  render() {
    const {
      topFacets,
      location,
      sort,
      filterType,
      activeFacets,
      nextCursor,
      currentCursor,
      prevCursor,
      isLoading,
      shouldRenderSortAndFilter,
      shouldRenderPlayAllButton,
      isPlaying,
      handleMultipleComposers,
      listIsEmpty,
    } = this.props;

    return (
      <div className={styles.component}>
        <div className={styles.container}>
          {this.renderToolbar()}
          {shouldRenderSortAndFilter && (
            <FacetGroups
              facets={topFacets}
              location={location}
              sort={sort}
              isVisible={this.isFilterVisible()}
              onClose={this.handleCloseFilter}
              activeFacets={activeFacets}
              loadAndShowAllFacets={this.loadAndShowAllFacets()}
              shouldRenderFacetGroup={this.shouldRenderFacetGroup}
              handleMultipleComposers={handleMultipleComposers}
            />
          )}
          {shouldRenderPlayAllButton && !listIsEmpty && (
            <PlayButton
              pausedTitle={this.getPlayButtonText()}
              playing={isPlaying}
              onClick={this.props.togglePlayAll}
              moduleVariant="header"
              labelVisible
              size="small"
            />
          )}
          {isLoading && !this.state.isLoadingMore ? (
            <LoadingIndicator isLoading />
          ) : (
            <PaginatedList
              isLoading={isLoading}
              currentCursor={currentCursor}
              nextCursor={nextCursor}
              prevCursor={prevCursor}
              location={location}
              loadMore={this.loadMore}
            >
              {this.renderFilteredList()}
            </PaginatedList>
          )}
        </div>
        <FacetGroupAllFacets
          location={location}
          activeFacets={activeFacets}
          filterType={filterType}
          handleMultipleComposers={handleMultipleComposers}
        />
      </div>
    );
  }

  loadMore = () => {
    this.setState({ isLoadingMore: true }, () => {
      /*
      KLUDGE for some reason fired twice by react-infinite-scroll without this check
      and at times also fired even if cursor is null
      */
      const { filterParams, nextCursor, isLoading } = this.props;
      if (!isLoading && nextCursor) {
        this.props.loadMore(filterParams, nextCursor, __CAPACITOR__ ? 20 : 50);
      }
    });
  };

  loadAndShowAllFacets = () => {
    const { loadAllFacets, showFacetGroupAllFacetsModal, filterParams } = this.props;
    return facetType => {
      loadAllFacets(facetType, filterParams);
      showFacetGroupAllFacetsModal(facetType, filterParams);
    };
  };

  handleOpenFilter = () => {
    this.setState({ filterOpen: true });
  };

  handleCloseFilter = () => {
    this.setState({ filterOpen: false });
  };

  isFilterVisible = () => {
    return this.state.filterOpen;
  };

  shouldRenderFacetGroup = facetInfo => {
    const { type, results, activeFacetsArePresent } = facetInfo;
    return (
      (!!results.length || activeFacetsArePresent) && !includes(this.props.ignoredFacets, type)
    );
  };
}

function mapStateToProps(state: Object, ownProps: OwnProps): MapStateToProps {
  const { activeFacets, topFacets, facetOrder } = ownProps;
  const sortedAndFilteredTopFacets = createSelectSortedTopFacetsGroups(
    topFacets,
    facetOrder,
    activeFacets
  );

  const hasActiveFacets = !!activeFacets.length;

  return {
    hasActiveFacets,
    topFacets: sortedAndFilteredTopFacets,
    shouldRenderSortAndFilter: ownProps.shouldRenderSortAndFilter === false ? false : true,
  };
}

const dispatchProps: DispatchProps = {
  showFacetGroupAllFacetsModal: uiActions.showFacetGroupAllFacetsModal,
  trackFilterReset: analyticsActions.trackFilterReset,
  trackFilterSelected: analyticsActions.trackFilterSelected,
};

export default compose(connect(mapStateToProps, dispatchProps), injectIntl)(FilteredList);
