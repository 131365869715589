// @flow
import * as React from 'react';

import ArtistFunctions from '../common/ArtistFunctions';
import ArtistAvatar from '../common/ArtistAvatar';

import type { LivestreamEventArtist } from '../../shapes/types';

import styles from './LivestreamEventArtistInfo.css';
import { IMAGES_BASE } from '../../constants';

const ARTIST_AVATAR_SIZE = 48;

type OwnProps = $Diff<LivestreamEventArtist, { externalUrl?: string }>;

type Props = OwnProps;

const LivestreamEventArtistInfo = ({ profileId, name, functions, imageUrl }: Props) => {
  const size = ARTIST_AVATAR_SIZE;
  const isContentfulImage = Boolean(imageUrl) && !imageUrl.startsWith(IMAGES_BASE);
  const src = isContentfulImage ? `${imageUrl}?fit=thumb&f=face&h=${size}&w=${size}` : imageUrl;
  return (
    <React.Fragment>
      <ArtistAvatar
        profileId={profileId}
        src={src}
        size={size}
        className={styles.img}
        isContentfulImage={isContentfulImage}
      />
      <div className={styles.content}>
        <span>{name}</span>
        {functions && functions.length && <span>,&nbsp;</span>}
        <ArtistFunctions className={styles.functions} functions={functions} />
      </div>
    </React.Fragment>
  );
};

export default LivestreamEventArtistInfo;
