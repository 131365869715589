import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { compose } from 'redux';

import * as playableItemPreviewActions from '../../actions/playableItemPreview';
import {
  selectPlaylistIsLoadingToPlay,
  selectPlaylistIsPlaying,
  selectPlaylistIsQueued,
} from '../../selectors/playlist';

import mediaObjectStyles from '../util/MediaObject.css';
import CapacitorRipple from '../capacitor/Ripple';

import queueOriginComponent, {
  queueOriginComponentPropTypes,
} from '../../hoc/queueOriginComponent';
import { Image } from '../util/Image';
import { CircularPlayButton } from './CircularPlayButton';
import Curator from './Curator';

import { playlistToPlayableItemPreview } from '../../lib/playableItemPreview';

import { QUEUE_TYPE_PLAYLIST, IMGIX_PARAMS } from '../../constants';

class PlaylistListItem extends Component {
  static propTypes = {
    playlist: PropTypes.object.isRequired,
    isPlaying: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired,
    togglePlay: PropTypes.func.isRequired,
    loadPlayableItemPreviewUrl: PropTypes.func.isRequired,
    ...queueOriginComponentPropTypes,
  };

  onMouseOver = () => {
    const playlist = this.props.playlist;
    this.props.loadPlayableItemPreviewUrl({
      title: playlist.title,
      imageUrl: playlist.imageUrl,
      content: {
        type: 'playlist',
        ...playlist,
      },
    });
  };

  onTogglePlay = playing => {
    const { playlist } = this.props;

    const trackedItem = playlistToPlayableItemPreview(playlist, 'playlist');

    this.trackPlayPausePressed(playlist, playing);

    return this.props.togglePlay(trackedItem, Date.now());
  };

  render() {
    const { playlist, isPlaying, isLoading } = this.props;
    const { curator } = playlist;

    return (
      <figure className={mediaObjectStyles.object} role="none">
        <Link
          to={`/playlists/${playlist.slug}`}
          className={mediaObjectStyles.anchor}
          title={playlist.title}
          onlyActiveOnIndex
          onMouseOver={this.onMouseOver}
          data-test="playlist-list-item-link"
        >
          <CapacitorRipple />
          <Image
            noBase
            src={playlist.imageUrl}
            auto={IMGIX_PARAMS}
            className={mediaObjectStyles.imageSlot}
            width={360}
            height={360}
            alt=""
          />
          <figcaption className={mediaObjectStyles.description}>
            <span className={mediaObjectStyles.title}>
              <span>{playlist.title}</span>
            </span>
            {curator && <Curator curator={curator} />}
          </figcaption>
        </Link>
        {!__CAPACITOR__ && (
          <CircularPlayButton
            onClick={this.onTogglePlay}
            playing={isPlaying}
            loading={isLoading}
            light
          />
        )}
      </figure>
    );
  }

  trackPlayPausePressed = (item, playing) => {
    this.props.trackPlayPausePressed(
      {
        contextId: item.id,
        contextType: 'playlist',
      },
      playing
    );
  };
}

function mapStateToProps(state, ownProps) {
  const { playlist } = ownProps;
  return {
    playlist,
    isPlaying: selectPlaylistIsPlaying(state, playlist.slug),
    isLoading: selectPlaylistIsLoadingToPlay(state, playlist.slug),
  };
}

function getQueueOrigin(originId) {
  return {
    type: QUEUE_TYPE_PLAYLIST,
    id: originId,
  };
}

function mapQueueOriginStateToProps(state, ownProps) {
  const { slug, tracks, trackIds, isPlaying } = ownProps.playlist;
  return {
    originId: slug,
    tracks,
    trackIds,
    isPlaying,
    isQueued: selectPlaylistIsQueued(state, slug, QUEUE_TYPE_PLAYLIST),
  };
}

function getTrackingData(props) {
  const { contextType, contextFacet, contextId } = props;

  const trackingData = { contextType };
  if (contextFacet) {
    trackingData.contextFacet = contextFacet;
  }

  if (contextId) {
    trackingData.contextId = contextId;
  }

  return trackingData;
}

export default compose(
  connect(
    mapStateToProps,
    {
      togglePlay: playableItemPreviewActions.togglePlayPlayableItemPreview,
      loadPlayableItemPreviewUrl: playableItemPreviewActions.loadPlayableItemPreviewUrl,
    },
    (stateProps, dispatchProps) => ({ ...stateProps, ...dispatchProps })
  ),
  queueOriginComponent(getQueueOrigin, mapQueueOriginStateToProps, getTrackingData)
)(PlaylistListItem);
