import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import IconLabel from '../util/IconLabel';
import styles from './DownloadIndicator.css';

const DownloadIndicator = ({ trackIsDownloaded, trackIsDownloading, className }) => {
  if (trackIsDownloaded) {
    return (
      <div className={classnames(className, styles.downloadIndicator)}>
        <IconLabel
          name="circled-arrow-down"
          title="Downloaded"
          size="small"
          className={styles.downloadIcon}
        />
      </div>
    );
  }
  if (trackIsDownloading) {
    return (
      <div className={classnames(className, styles.downloadIndicator)}>
        <div className={styles.spinner} />
      </div>
    );
  }
  return null;
};

DownloadIndicator.propTypes = {
  trackIsDownloaded: PropTypes.bool,
  trackIsDownloading: PropTypes.bool,
  className: PropTypes.string,
};

export default DownloadIndicator;
