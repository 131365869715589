// @flow
// $FlowFixMe
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';

import * as uiActions from '../../actions/ui';
import { getPluralMessage } from '../messages/FilterMessages';
import { injectIntl } from 'react-intl';
import type { IntlShape } from 'react-intl';
import FacetGroupAllFacetsContent from './FacetGroupAllFacetsContent';
import { selectIsBoolean } from '../../selectors/facet';
import FacetGroupAllFacetsModal from '../modals/FacetGroupAllFacetsModal';
import FacetGroupAllFacetsOverlay from '../modals/FacetGroupAllFacetsOverlay';
import { useDesktopMediaQuery } from '../../utils/useMediaQuery';

type OwnProps = {
  location: Object,
  activeFacets: Array<any>,
  filterType: string,
  handleMultipleComposers?: boolean,
};

type MapStateToProps = {
  isVisible: boolean,
  type: string | null,
  id: string | null,
  isBoolean: boolean,
};

type DispatchProps = {
  hideFacetGroupAllFacets: Function,
};

type Props = OwnProps & MapStateToProps & DispatchProps & { intl: IntlShape };

const FacetGroupAllFacets = ({
  isVisible,
  hideFacetGroupAllFacets,
  type,
  id,
  filterType,
  location,
  activeFacets,
  isBoolean,
  intl,
  handleMultipleComposers,
}: Props) => {
  const [selectedFacetIds, setSelectedFacetIds] = useState([]);
  const [shouldRenderApplyFiltersBtn, setShouldRenderApplyFiltersBtn] = useState(false);
  const filteredActiveFacets = useMemo(
    () => activeFacets.filter(facet => facet.type === type),
    [activeFacets, type]
  );

  const activeFacetIds = useMemo(
    () =>
      filteredActiveFacets.map(activeFacet =>
        isBoolean ? activeFacet.facet.value : activeFacet.facet.id
      ),
    [filteredActiveFacets, isBoolean]
  );

  useEffect(() => {
    setSelectedFacetIds(activeFacetIds);
    // Only use type in the dep array since the activeFacetIds also change when
    // you apply filters in the overlay window on mobile/capacitor
  }, [type]); // eslint-disable-line react-hooks/exhaustive-deps

  const onClickFacet = useCallback(
    (event, newId) => {
      event.preventDefault();
      setSelectedFacetIds(
        selectedFacetIds.includes(newId)
          ? selectedFacetIds.filter(facetId => facetId !== newId)
          : [...selectedFacetIds, newId]
      );
    },
    [selectedFacetIds]
  );

  useEffect(() => {
    if (selectedFacetIds) {
      const isEqual =
        JSON.stringify(selectedFacetIds.sort()) === JSON.stringify(activeFacetIds.sort());
      setShouldRenderApplyFiltersBtn(!isEqual);
    }
  }, [selectedFacetIds, activeFacetIds]);

  const onClose = () => {
    hideFacetGroupAllFacets();
  };

  const onClickClear = () => {
    setSelectedFacetIds([]);
    onClose();
  };

  const isDesktop = useDesktopMediaQuery();
  const Wrapper = isDesktop ? FacetGroupAllFacetsModal : FacetGroupAllFacetsOverlay;

  return (
    <Wrapper
      isVisible={isVisible}
      hideModal={onClose}
      title={getPluralMessage(intl, type)}
      selectedFacetIds={selectedFacetIds}
      location={location}
      type={type}
      onClickClear={onClickClear}
    >
      {type && id && (
        <FacetGroupAllFacetsContent
          location={location}
          activeFacets={filteredActiveFacets}
          filterType={filterType}
          onClose={onClose}
          type={type}
          id={id}
          isBoolean={isBoolean}
          onClickClear={onClickClear}
          selectedFacetIds={selectedFacetIds}
          onClickFacet={onClickFacet}
          shouldRenderApplyFiltersBtn={shouldRenderApplyFiltersBtn}
          handleMultipleComposers={handleMultipleComposers}
        />
      )}
    </Wrapper>
  );
};

function mapStateToProps(state: Object): MapStateToProps {
  const currentFacet = state.client.ui.facet;
  const type = currentFacet ? currentFacet.type : null;
  const id = currentFacet ? currentFacet.id : null;

  return {
    isVisible: !!id,
    type,
    id,
    isBoolean: selectIsBoolean(type),
  };
}

const dispatchProps: DispatchProps = {
  hideFacetGroupAllFacets: uiActions.hideFacetGroupAllFacetsModal,
};

export default compose(connect(mapStateToProps, dispatchProps), injectIntl)(FacetGroupAllFacets);
