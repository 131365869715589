// @flow
import React from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { INLINES } from '@contentful/rich-text-types';
import { FormattedDate, FormattedTime, FormattedMessage } from 'react-intl';

import CourseSessionItem from './CourseSessionItem';
import AccessZoomButton from './AccessZoomButton';
import Recording from './CourseSessionRecording';
import CourseSessionResource from './CourseSessionResource';
import type { CourseSession as CourseSessionProps } from '../../shapes/types';
import styles from './CourseSession.css';
import bodyStyles from '../../views/LivestreamEventBody.css';
import ContentfulLink from '../util/ContentfulLink';
import CourseSessionDuration from './CourseSessionDuration';

const getOptions = () => ({
  renderNode: {
    [INLINES.HYPERLINK]: (node, children) => (
      <ContentfulLink node={node} fromId="courseSessionBody">
        {children}
      </ContentfulLink>
    ),
  },
});

type OwnProps = {
  session: CourseSessionProps,
  slug: string,
  userHasAccess: boolean,
  location: Object,
};

type Props = OwnProps;

const CourseSession = ({ session, slug, userHasAccess, location }: Props) => {
  const {
    id,
    canJoinMeeting,
    hasEnded,
    recordingUrl,
    recordingPassword,
    title: sessionTitle,
    body,
    imageUrl,
    start,
    durationInMins,
    sessionNo,
    resources,
  } = session;

  const Image = () => {
    if (!imageUrl) {
      return null;
    }

    const backgroundImage = `url(${imageUrl}?fm=jpg&fl=progressive)`;
    return <figure className={styles.imageWrapper} style={{ backgroundImage }} role="none" />;
  };

  const CollapsibleTitle = () => (
    <div>
      <FormattedMessage
        id="course.session.collapsible-title"
        defaultMessage="{tickSymbol} Session {sessionNo} - {startDate} | {startTime}"
        values={{
          tickSymbol: hasEnded ? '\u2713' : '',
          sessionNo,
          startDate: <FormattedDate weekday="short" month="short" day="numeric" value={start} />,
          startTime: <FormattedTime timeZoneName="short" value={start} />,
        }}
      />
    </div>
  );

  const AccessSessionButton = () => {
    if (!userHasAccess) {
      return null;
    }

    const className = `c-btn c-btn--is-inverted ${styles.ctaButton}`;

    if (!hasEnded) {
      return (
        <AccessZoomButton
          slug={slug}
          id={id}
          className={className}
          location={location}
          isDisabled={!canJoinMeeting}
          sessionNo={sessionNo}
        />
      );
    }

    if (recordingUrl) {
      return (
        <Recording
          url={recordingUrl}
          className={className}
          password={recordingPassword}
          sessionNo={sessionNo}
        />
      );
    }

    return null;
  };

  const Resources = () => {
    if (!userHasAccess || !resources || !resources.length) {
      return null;
    }

    return (
      <div className={styles.resource} data-test="livestream-event.course-session.resources">
        <h2>
          <FormattedMessage id="course.session.resource.title" defaultMessage="Session resources" />
        </h2>
        {resources.map((resource, index) => {
          return <CourseSessionResource key={index} resource={resource} />;
        })}
      </div>
    );
  };

  return (
    <CourseSessionItem title={<CollapsibleTitle />} hasEnded={hasEnded}>
      <Image />
      <h2>{sessionTitle}</h2>
      <div className={bodyStyles.container}>
        {documentToReactComponents(body, getOptions())}
        <p className={styles.duration}>
          <CourseSessionDuration durationInMins={durationInMins} />
        </p>
      </div>
      <div className={styles.accessSessionButton}>
        <AccessSessionButton />
      </div>
      <Resources />
    </CourseSessionItem>
  );
};

export default CourseSession;
