import PropTypes from 'prop-types';
import { Link } from 'react-router';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

import * as playableItemPreviewActions from '../../actions/playableItemPreview';
import {
  selectAlbumIsLoadingToPlay,
  selectAlbumIsPlaying,
  selectAlbumIsQueued,
} from '../../selectors/album';
import mediaObjectStyles from '../util/MediaObject.css';
import { Image } from '../util/Image';
import CapacitorRipple from '../capacitor/Ripple';
import AlbumOneLineParticipantSummary from './AlbumOneLineParticipantSummary';
import queueOriginComponent, {
  queueOriginComponentPropTypes,
} from '../../hoc/queueOriginComponent';
import { IMGIX_PARAMS, QUEUE_TYPE_ALBUM } from '../../constants';
import { CircularPlayButton } from './CircularPlayButton';

function filterComposersOut(participants) {
  return participants.filter(participant => participant.type !== 'composer');
}

class AlbumListItem extends Component {
  static propTypes = {
    album: PropTypes.object.isRequired,
    isPlaying: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired,
    togglePlay: PropTypes.func.isRequired,
    loadPlayableItemPreviewUrl: PropTypes.func.isRequired,
    ...queueOriginComponentPropTypes,
  };

  onMouseOver = () => {
    const album = this.props.album;
    this.props.loadPlayableItemPreviewUrl({
      title: album.title,
      imageUrl: album.imageUrl,
      content: {
        type: 'album',
        ...album,
      },
    });
  };

  onTogglePlay = playing => {
    const { album } = this.props;

    const transformedAlbum = {
      title: album.title,
      imageUrl: album.imageUrl,
      content: {
        type: 'album',
        ...album,
      },
    };

    this.trackPlayPausePressed(album, playing);

    return this.props.togglePlay(transformedAlbum, Date.now());
  };

  render() {
    const { album, isPlaying, isLoading } = this.props;

    return (
      <figure className={mediaObjectStyles.object} role="none">
        <Link
          to={`/albums/${album.slug}`}
          className={mediaObjectStyles.anchor}
          onMouseOver={this.onMouseOver}
          data-test="album-list-item-link"
        >
          <CapacitorRipple />
          <Image
            noBase
            src={album.imageUrl}
            auto={IMGIX_PARAMS}
            className={mediaObjectStyles.imageSlot}
            width={360}
            height={360}
            alt=""
          />
          <figcaption className={mediaObjectStyles.description}>
            <span className={mediaObjectStyles.title}>
              <span>{album.title}</span>
            </span>
            <AlbumOneLineParticipantSummary
              className={mediaObjectStyles.performerSummary}
              participants={filterComposersOut(album.participants)}
            />
            <span className={mediaObjectStyles.performerSummary}>{album.copyright}</span>
          </figcaption>
        </Link>
        {!__CAPACITOR__ && (
          <CircularPlayButton
            onClick={this.onTogglePlay}
            playing={isPlaying}
            loading={isLoading}
            light
          />
        )}
      </figure>
    );
  }

  trackPlayPausePressed = (item, playing) => {
    this.props.trackPlayPausePressed(
      {
        contextId: item.id,
        contextType: 'album',
      },
      playing
    );
  };
}

function mapStateToProps(state, ownProps) {
  const { album } = ownProps;
  return {
    album,
    isPlaying: selectAlbumIsPlaying(state, album.slug),
    isLoading: selectAlbumIsLoadingToPlay(state, album.slug),
  };
}

function getQueueOrigin(originId) {
  return {
    type: QUEUE_TYPE_ALBUM,
    id: originId,
  };
}

function mapQueueOriginStateToProps(state, ownProps) {
  const { id, tracks, trackIds } = ownProps.album;
  return {
    originId: id.toString(),
    tracks,
    trackIds,
    isPlaying: selectAlbumIsPlaying(state, id, QUEUE_TYPE_ALBUM),
    isQueued: selectAlbumIsQueued(state, id, QUEUE_TYPE_ALBUM),
  };
}

function getTrackingData(props) {
  const { contextType, contextFacet, contextId } = props;

  const trackingData = { contextType };
  if (contextFacet) {
    trackingData.contextFacet = contextFacet;
  }

  if (contextId) {
    trackingData.contextId = contextId;
  }

  return trackingData;
}

export default compose(
  connect(
    mapStateToProps,
    {
      togglePlay: playableItemPreviewActions.togglePlayPlayableItemPreview,
      loadPlayableItemPreviewUrl: playableItemPreviewActions.loadPlayableItemPreviewUrl,
    },
    (stateProps, dispatchProps) => ({ ...stateProps, ...dispatchProps })
  ),
  queueOriginComponent(getQueueOrigin, mapQueueOriginStateToProps, getTrackingData)
)(AlbumListItem);
