// @flow
import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import dataComponent from '../hoc/dataComponent';
import * as searchActions from '../actions/search';
import SearchResults from '../components/search/SearchResults';
import QuickSearch from '../components/search/QuickSearch';
import {
  selectSearchQuery,
  selectSearchResults,
  selectSearchIsLoaded,
  selectSearchFilters,
  selectSearchActiveFilter,
} from '../selectors/search';
import styles from './Search.css';
import SearchFilters from '../components/search/SearchFilters';
import { MIN_SEARCH_QUERY_LENGTH } from '../constants';

type MapStateToProps = {
  query: string,
  results: Array<any>,
  loaded: boolean,
  filters: Array<any>,
  activeFilter: string | null,
};

type DispatchProps = {
  completeSearch: Function,
  setSearchActiveFilter: Function,
};

type Props = MapStateToProps & DispatchProps;

declare var __CAPACITOR__: boolean;

const Search = ({
  query,
  results,
  loaded,
  completeSearch,
  filters,
  setSearchActiveFilter,
  activeFilter,
}: Props) => {
  const isValidQuery = !!query && query.length >= MIN_SEARCH_QUERY_LENGTH;

  return (
    <div className="u-page-container">
      {__CAPACITOR__ && (
        <div className={styles.capacitorSearchBar}>
          <QuickSearch />
        </div>
      )}
      <div className={styles.inner}>
        <SearchFilters
          filters={filters}
          onFilterSelect={setSearchActiveFilter}
          activeFilter={activeFilter}
          isValidQuery={isValidQuery}
        />
        <SearchResults
          isValidQuery={isValidQuery}
          loaded={loaded}
          results={results}
          onAccessed={completeSearch}
          hideHeading={!!activeFilter}
        />
      </div>
    </div>
  );
};

function fetchData(store, routerParams, location) {
  if (location.query.query) {
    const query = location.query.query;
    store.dispatch(searchActions.setQuickSearchQuery(query));
    return store.dispatch(searchActions.loadSearch(query));
  }
  return false;
}

function mapStateToProps(state: Object): MapStateToProps {
  return {
    query: selectSearchQuery(state),
    results: selectSearchResults(state),
    loaded: selectSearchIsLoaded(state),
    filters: selectSearchFilters(state),
    activeFilter: selectSearchActiveFilter(state),
  };
}

const dispatchProps: DispatchProps = {
  completeSearch: searchActions.completeSearch,
  setSearchActiveFilter: searchActions.setSearchActiveFilter,
};

export default compose(dataComponent(fetchData), connect(mapStateToProps, dispatchProps))(Search);
