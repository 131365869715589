// @flow
// $FlowFixMe
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import * as notificationActions from '../../actions/notifications';
import * as uiActions from '../../actions/ui';
import * as meActions from '../../actions/me';
import * as subscriptionActions from '../../actions/subscription';
import * as analyticsActions from '../../actions/analytics';

import {
  selectSubscriptionIsLoaded,
  selectSubscriptionIsLoading,
} from '../../selectors/subscription';

import LoadingIndicator from '../common/LoadingIndicator';

import { CANCEL_SUBSCRIPTION_ERROR } from '../../lib/notifications';

import CurrentPlan from './CurrentPlan';
import NextBilling from './NextBilling';
import Voucher from './Voucher';
import SubscriptionEnd from './SubscriptionEnd';

type OwnProps = {};

type MapStateToProps = {
  subscriptionLoading: boolean,
  subscriptionLoaded: boolean,
};

type DispatchProps = {
  loadMe: Function,
  loadSubscription: Function,
  addNotification: Function,
  dismissNotification: Function,
  cancelSubscription: Function,
  reactivateSubscription: Function,
  sendSubscriptionCancellationFeedback: Function,
  showModal: Function,
  trackCancelButtonPressed: Function,
};

type Props = OwnProps & MapStateToProps & DispatchProps;

const AccountSubscription = ({
  subscriptionLoading,
  subscriptionLoaded,
  loadMe,
  loadSubscription,
  addNotification,
  dismissNotification,
  cancelSubscription,
  reactivateSubscription,
  sendSubscriptionCancellationFeedback,
  showModal,
  trackCancelButtonPressed,
}: Props) => {
  const [cancelDialogIsOpen, setCancelDialogIsOpen] = useState(false);
  const [cancelFeedbackIsOpen, setCancelFeedbackIsOpen] = useState(false);
  const [cancelSubscriptionIsInProgress, setCancelSubscriptionIsInProgress] = useState(false);
  const [reactivateSubscriptionIsInProgress, setReactivateSubscriptionIsInProgress] =
    useState(false);

  useEffect(() => {
    loadSubscription();
  }, [loadSubscription]);

  const onCancelSubscription = event => {
    event.preventDefault();
    trackCancelButtonPressed();
    setCancelDialogIsOpen(true);
  };

  const onKeepPremiumSubscription = () => {
    setCancelDialogIsOpen(false);
  };

  const onConfirmCancelSubscription = async () => {
    setCancelSubscriptionIsInProgress(true);

    try {
      await cancelSubscription();
      await loadMe();
      setCancelSubscriptionIsInProgress(false);
      setCancelDialogIsOpen(false);
      setCancelFeedbackIsOpen(true);
      dismissNotification('subscription');
    } catch (err) {
      setCancelSubscriptionIsInProgress(false);
      addNotification(CANCEL_SUBSCRIPTION_ERROR);
    }
  };

  const onSendFeedback = (event, feedbackReason, feedbackComment) => {
    event.preventDefault();

    if (!feedbackReason && !feedbackComment) {
      return;
    }

    const data = {
      reason: feedbackReason,
      comment: feedbackComment,
    };

    sendSubscriptionCancellationFeedback(data);
    setCancelFeedbackIsOpen(false);
  };

  const onRejoinPremium = async event => {
    event.preventDefault();
    setReactivateSubscriptionIsInProgress(true);

    try {
      await reactivateSubscription();
      await loadMe();
    } catch (err) {
      setReactivateSubscriptionIsInProgress(false);
    }

    setReactivateSubscriptionIsInProgress(false);
  };

  const onSubscribeClick = () => {
    showModal('SUBSCRIBE_MODAL', { trigger: 'settings' });
  };

  if (
    cancelSubscriptionIsInProgress ||
    reactivateSubscriptionIsInProgress ||
    !subscriptionLoaded ||
    subscriptionLoading
  ) {
    return <LoadingIndicator isLoading />;
  }

  return (
    <React.Fragment>
      <CurrentPlan onSubscribeClick={onSubscribeClick} />
      <NextBilling
        cancelSubscriptionDialogIsOpen={cancelDialogIsOpen}
        onCancelSubscription={onCancelSubscription}
        onKeepPremiumSubscription={onKeepPremiumSubscription}
        onConfirmCancelSubscription={onConfirmCancelSubscription}
      />
      <SubscriptionEnd
        onSendFeedback={onSendFeedback}
        cancelFeedbackIsOpen={cancelFeedbackIsOpen}
        onRejoinPremium={onRejoinPremium}
      />
      <Voucher />
    </React.Fragment>
  );
};

function mapStateToProps(state: Object): MapStateToProps {
  return {
    subscriptionLoading: selectSubscriptionIsLoading(state),
    subscriptionLoaded: selectSubscriptionIsLoaded(state),
  };
}

const dispatchProps: DispatchProps = {
  loadMe: meActions.loadMe,
  loadSubscription: subscriptionActions.loadSubscription,
  addNotification: notificationActions.addNotification,
  dismissNotification: notificationActions.dismissNotification,
  cancelSubscription: subscriptionActions.cancelSubscription,
  reactivateSubscription: subscriptionActions.reactivateSubscription,
  sendSubscriptionCancellationFeedback: subscriptionActions.sendSubscriptionCancellationFeedback,
  showModal: uiActions.showModal,
  trackCancelButtonPressed: analyticsActions.trackCancelButtonPressed,
};

export default connect(mapStateToProps, dispatchProps)(AccountSubscription);
