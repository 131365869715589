import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Link } from 'react-router';
import classnames from 'classnames';

export default class Button extends PureComponent {
  static propTypes = {
    outline: PropTypes.bool,
    purple: PropTypes.bool,
    onClick: PropTypes.func,
    link: PropTypes.string.isRequired,
    activeClassName: PropTypes.string,
    noButtonClass: PropTypes.bool,
    className: PropTypes.string,
    children: PropTypes.node,
  };

  static defaultProps = {
    outline: false,
    purple: false,
    onClick: () => {},
    noButtonClass: false,
  };

  render() {
    const { link, activeClassName, noButtonClass, className: propsClassName } = this.props;
    const className = classnames(
      noButtonClass ? '' : 'c-btn',
      {
        'c-btn--is-purple': this.props.purple,
        'c-btn--has-outline': this.props.outline,
      },
      propsClassName
    );
    const isExternalLink = link && link.startsWith('http');
    const button = isExternalLink ? (
      <a onClick={this.props.onClick} href={link} className={className}>
        {this.props.children}
      </a>
    ) : (
      <Link
        onClick={this.props.onClick}
        to={link}
        className={className}
        activeClassName={activeClassName}
      >
        {this.props.children}
      </Link>
    );

    return button;
  }
}
