// @flow
import React from 'react';
import { compose } from 'redux';
import { defineMessages, injectIntl } from 'react-intl';
import type { IntlShape } from 'react-intl';
import dataComponent from '../hoc/dataComponent';

import Head from '../components/chrome/Head';
import ConversionForm from '../components/premium/ConversionForm';

import { CHROME_CLEAN_LINKS } from '../constants';

import chromeComponent from '../hoc/chromeComponent';
import trackedViewComponent from '../hoc/trackedViewComponent';

import RedirectInstruction from '../lib/routing/RedirectInstruction';

import styles from './Subscribe.css';
import { PLEASE_WHILE_REDIRECTING } from '../lib/notifications';
import { connect } from 'react-redux';
import * as notificationsActions from '../actions/notifications';
import { selectUserIsPatron } from '../selectors/user';
import { selectSubscriptionFromId } from '../selectors/subscriptionOffer';

type OwnProps = {
  router: Object,
};

type DispatchProps = {
  addNotification: Function,
};

type Props = OwnProps & DispatchProps & { intl: IntlShape };

const messages = defineMessages({
  metaTitle: {
    id: 'subscribe.title',
    defaultMessage: 'Subscribe',
  },
});

const Subscribe = ({ router, intl, addNotification }: Props) => {
  const onComplete = async () => {
    addNotification(PLEASE_WHILE_REDIRECTING);
    document.location.href = '/';
  };

  const onClickLoginLink = async event => {
    event.preventDefault();
    await router.push('/login?to=%2Fsubscribe');
  };

  const { formatMessage } = intl;
  return (
    <div className={styles.wrapper}>
      <Head title={formatMessage(messages.metaTitle)} />
      <div className={styles.form} id="start">
        <ConversionForm onComplete={onComplete} onClickLoginLink={onClickLoginLink} />
      </div>
    </div>
  );
};

function fetchData(store) {
  const state = store.getState();
  const shouldRedirect = selectUserIsPatron(state);
  if (shouldRedirect) {
    return Promise.reject(new RedirectInstruction('/discover'));
  }

  return [];
}

const trackingProps = state => {
  return {
    landing_page_id: selectSubscriptionFromId(state),
  };
};

const dispatchProps: DispatchProps = {
  addNotification: notificationsActions.addNotification,
};

export default compose(
  connect(null, dispatchProps),
  trackedViewComponent('Subscribe', trackingProps),
  chromeComponent({ type: CHROME_CLEAN_LINKS }),
  dataComponent(fetchData),
  injectIntl
)(Subscribe);
