import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

import getIsAccessibleRecording from '../../utils/getIsAccessibleRecording';
import InfiniteScroll from '../../vendor/InfiniteScroll';

import AnimatedList from '../util/AnimatedList';
import PlaylistItem from '../playlist/PlaylistItem';
import LoadingIndicator from '../common/LoadingIndicator';
import * as collectionActions from '../../actions/collection';
import * as uiActions from '../../actions/ui';

import isDuplicatePiece from '../../utils/isDuplicatePiece';
import { selectUser } from '../../selectors/user';
import {
  getTrackCollectionOrigin,
  selectCollectionIds,
  selectNextTrackCollectionCursor,
  selectTrackCollection,
} from '../../selectors/collection';

import { TRACK_COLLECTION_ORIGIN_ID, ENTITY_TYPE_TRACK } from '../../constants';

import stylesCollection from '../../views/Collection.css';
import stylesTrackCollection from './TrackCollection.css';
import dataComponent from '../../hoc/dataComponent';
import queueOriginComponent, {
  queueOriginComponentPropTypes,
} from '../../hoc/queueOriginComponent';
import { selectIsQueueOriginPlaying, selectIsQueueOriginSetAs } from '../../selectors/queue';

import EmptyCollection from './EmptyCollection';
import CollectionTracks from '../../components/messages/CollectionTracks';
import protectedComponent from '../../hoc/protectedComponent';
import playlistableEntity, { playlistableEntityPropTypes } from '../../hoc/playlistableEntity';

class TrackCollection extends Component {
  static propTypes = {
    loadTrackCollection: PropTypes.func.isRequired,
    nextCursor: PropTypes.string,
    user: PropTypes.object.isRequired,
    isLoading: PropTypes.bool.isRequired,
    showAddToPlaylistModal: PropTypes.func.isRequired,

    ...queueOriginComponentPropTypes,
    ...playlistableEntityPropTypes,
  };

  state = {
    expandedTracks: {},
  };

  onClickTrack = (trackId, playing) => {
    this.props.playFromTrack(trackId, playing);
  };

  renderTrackList() {
    const { tracks, isLoading } = this.props;
    return (
      <div className={stylesTrackCollection.tracks}>
        <InfiniteScroll
          pageStart={0}
          loadMore={this.loadMore}
          hasMore={!!this.props.nextCursor}
          loader={null}
          className={stylesTrackCollection.infiniteScroll}
          scrollableSelector={'.content'}
          useWindow={false}
        >
          <AnimatedList
            renderItem={this.renderTrackItem}
            items={tracks}
            transitionName="anim"
            transitionEnterTimeout={350}
          />
        </InfiniteScroll>
        <LoadingIndicator isLoading={isLoading} />
      </div>
    );
  }

  renderTrackItem = (track, index) => {
    const { user, tracks, onAddTrackToPlaylistClick } = this.props;

    const isCurrentTrack = this.props.isQueuedTrack(track.id, index);
    const isPlaying = this.props.isPlayingTrack(track.id, index);
    const isAccessible = getIsAccessibleRecording(user, track.recording);
    const isDuplicate = isDuplicatePiece(tracks, track.piece.id);

    return (
      <PlaylistItem
        data-test="collection.tracks-item"
        key={`${track.id}_${index}`}
        indexInPlaylist={index}
        track={track}
        isCurrentTrack={isCurrentTrack}
        playing={isPlaying}
        isAccessible={isAccessible}
        onClick={this.onClickTrack}
        isDuplicatePiece={isDuplicate}
        onAddToPlaylistButtonClick={onAddTrackToPlaylistClick}
        isExpanded={this.state.expandedTracks[track.id]}
        toggleExpanded={() => this.toggleExpanded(track.id)}
      />
    );
  };

  render() {
    if (!this.props.trackIds.length) {
      return <EmptyCollection entityType={ENTITY_TYPE_TRACK} />;
    }
    return (
      /* track-collection stays in there for the time being until we've figured out AnimatedList */
      <div data-test-collection-track>
        <div className={stylesCollection.header}>
          <h1 className="fz--beta">
            <CollectionTracks />
          </h1>
        </div>
        {this.renderTrackList()}
      </div>
    );
  }

  loadMore = () => {
    this.props.loadTrackCollection(this.props.nextCursor);
  };

  toggleExpanded = id => {
    this.setState({
      expandedTracks: {
        ...this.state.expandedTracks,
        [id]: !this.state.expandedTracks[id],
      },
    });
  };
}

function mapStateToProps(state) {
  return {
    user: selectUser(state),
    isLoading: state.lists.collection.tracks.loading,
    nextCursor: selectNextTrackCollectionCursor(state),
  };
}

const connectComponent = connect(mapStateToProps, {
  loadTrackCollection: collectionActions.loadTrackCollection,
  showAddToPlaylistModal: uiActions.showAddToPlaylistModal,
});

function fetchData(store) {
  return store.dispatch(collectionActions.loadTrackCollection());
}

function mapQueueOriginStateToProps(state) {
  const queueOrigin = getTrackCollectionOrigin();
  const isQueued = selectIsQueueOriginSetAs(state, queueOrigin);
  const isPlaying = selectIsQueueOriginPlaying(state, queueOrigin);
  return {
    originId: TRACK_COLLECTION_ORIGIN_ID,

    tracks: selectTrackCollection(state),
    trackIds: selectCollectionIds(state).entities.trackIds,

    isPlaying,
    isQueued,
  };
}

function getTrackingContext() {
  return {
    contextType: 'favouriteTracks',
  };
}

function getEntityTrackingData() {
  return {
    itemType: 'track',
  };
}

export default compose(
  protectedComponent({ authenticatedOnly: true }),
  dataComponent(fetchData),
  queueOriginComponent(getTrackCollectionOrigin, mapQueueOriginStateToProps, getTrackingContext),
  connectComponent,
  playlistableEntity({
    getEntityTrackingData,
    getTrackingContext,
  })
)(TrackCollection);

export { TrackCollection as PureTrackCollection };
