import React, { Component } from 'react';
import { compose } from 'redux';

import { CHROME_CLEAN } from '../constants';
import chromeComponent from '../hoc/chromeComponent';

import ConsentManagerComponent from '../components/consent-manager/ConsentManager';
import ConsentManagerBuilder from '../components/consent-manager/ConsentManagerBuilder';

class ConsentManager extends Component {
  render() {
    return (
      <section className="u-page-container">
        <ConsentManagerBuilder>
          {({ preferences, setPreferences, saveConsent, onAccept, hasError }) => (
            <ConsentManagerComponent
              hasError={hasError}
              onAccept={onAccept}
              setPreferences={setPreferences}
              saveConsent={saveConsent}
              preferences={preferences}
            />
          )}
        </ConsentManagerBuilder>
      </section>
    );
  }
}

export default compose(chromeComponent({ type: CHROME_CLEAN }))(ConsentManager);
