import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { defineMessages, intlShape, injectIntl } from 'react-intl';

import * as Shapes from '../../shapes';
import Head from '../chrome/Head';
import NotFound from '../../views/NotFound';
import PlayRadioButton from './PlayRadioButton';
import CapacitorHeaderBar from '../capacitor/HeaderBar';

import styles from './CategoryPage.css';
import FilteredRecordingList from './FilteredRecordingList';
import { compose } from 'redux';
import shareableEntity, { shareableEntityPropTypes } from '../../hoc/shareableEntity';
import ShareButton from './ShareButton';

const messages = defineMessages({
  metaDescription: {
    id: 'category-page.meta.description',
    defaultMessage:
      'Quickly find and play {categoryTitle} music from our curated repertoire, including recordings from classical masters and contemporary artists. Browse by composer, performer, instrument, genre or period.',
  },
});

class CategoryPage extends Component {
  static propTypes = {
    category: Shapes.Category.isRequired,
    filterParams: PropTypes.object.isRequired,
    activeFacets: PropTypes.array.isRequired,
    ignoredFacets: PropTypes.array.isRequired,

    nextCursor: PropTypes.string,
    prevCursor: PropTypes.string,
    currentCursor: PropTypes.string,

    location: PropTypes.object.isRequired,
    params: PropTypes.object.isRequired,

    contextId: PropTypes.string.isRequired,
    contextFacet: PropTypes.string.isRequired,

    intl: intlShape.isRequired,

    ...shareableEntityPropTypes,
  };

  renderHeader() {
    const { category, contextFacet, location, contextId, showShareModal } = this.props;

    const radioTrackingContext = {
      contextFilters: new URLSearchParams(location.query).toString(),
      contextId: contextId,
      contextTitle: contextFacet,
      contextFacet: contextFacet,
      contextType: 'Category',
    };

    return (
      <div className={styles.header}>
        <h1 className={styles.title}>{category.title}</h1>
        <PlayRadioButton
          type={contextFacet.toLowerCase()}
          id={category.id}
          trackingContext={radioTrackingContext}
          data-test="category-page.play-radio-btn"
        />
        {!__CAPACITOR__ && <ShareButton onClick={showShareModal} className={styles.shareBtn} />}
      </div>
    );
  }

  render() {
    const {
      contextId,
      contextFacet,
      category,
      intl,
      nextCursor,
      prevCursor,
      currentCursor,
      location,
      params,
      filterParams,
      activeFacets,
      ignoredFacets,
    } = this.props;
    const { formatMessage } = intl;

    if (!category) {
      return <NotFound />;
    }

    return (
      <div className="category u-page-container">
        <Head
          title={category.title}
          description={formatMessage(messages.metaDescription, {
            categoryTitle: category.title.toLowerCase(),
          })}
          currentCursor={currentCursor}
          nextCursor={nextCursor}
          prevCursor={prevCursor}
          canonicalRoute={location.basename + location.pathname}
        />
        {__CAPACITOR__ && (
          <CapacitorHeaderBar title={this.props.category.title} onlyShowTitleOnScroll />
        )}
        {this.renderHeader()}
        <FilteredRecordingList
          location={location}
          params={params}
          contextId={contextId}
          contextFacet={contextFacet}
          filterParams={filterParams}
          activeFacets={activeFacets}
          ignoredFacets={ignoredFacets}
        />
      </div>
    );
  }
}

function getShareUrl({ location }) {
  return `/${location.pathname}/`;
}

function getShareTrackingContext(props) {
  return {
    sharedContentType: props.contextFacet,
    sharedContentId: props.category.id,
    contextType: 'Category',
    contentId: props.category.id,
  };
}

const getEntityType = props => props.contextFacet.toLowerCase();

export default compose(
  injectIntl,
  shareableEntity(getShareUrl, getShareTrackingContext, getEntityType)
)(CategoryPage);
