// @flow
import React from 'react';
import classnames from 'classnames';
import { Link } from 'react-router';

import CapacitorRipple from '../capacitor/Ripple';
import IconLabel from '../util/IconLabel';
import AlbumOneLineParticipantSummary from '../common/AlbumOneLineParticipantSummary';
import { Image } from '../util/Image';
import Curator from '../common/Curator';
import styles from './DiscoverCompactItem.css';
import { getQueueOrigin, getQueueOriginUrl } from '../../lib/queue';
import { IMGIX_PARAMS } from '../../constants';

type OwnProps = {
  item: Object,
  onClick: Function,
};

type Props = OwnProps;

const DiscoverCompactItem = ({ item, onClick }: Props) => {
  const { content, imageUrl, title, subtitle } = item;
  const { composerName, curator, type, participants } = content;

  const queueOrigin = getQueueOrigin(type, item.content);
  const url = getQueueOriginUrl(queueOrigin);

  const handleClick = () => {
    onClick(item);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.cover}>
        <Image noBase src={imageUrl} width={56} height={56} auto={IMGIX_PARAMS} alt="" />
      </div>
      <div className={styles.info}>
        <div className="c-text--is-truncated">
          <Link
            to={url}
            className={classnames(styles.link, 'c-link--expand-hitbox')}
            onClick={handleClick}
          >
            <CapacitorRipple />
            {title}
          </Link>
        </div>
        <div>
          {composerName && (
            <div className={classnames('c-text--is-truncated', styles.subtitle)}>
              {composerName}
            </div>
          )}
          {participants && (
            <div className={classnames('c-text--is-truncated', styles.subtitle)}>
              <AlbumOneLineParticipantSummary participants={participants} />
            </div>
          )}
          {subtitle && (
            <div className={classnames('c-text--is-truncated', styles.subtitle)}>{subtitle}</div>
          )}
          {curator && (
            <div className="c-text--is-truncated">
              <Curator curator={curator} />
            </div>
          )}
        </div>
      </div>
      <div className={styles.icon}>
        <IconLabel name="caret-right" />
      </div>
    </div>
  );
};

export default DiscoverCompactItem;
