/* This file is pretty similar to SonosSignupFlow. Changes in either may be needed to be reflected in the other */

import React, { Component } from 'react';
import { defineMessages, FormattedMessage, injectIntl, intlShape } from 'react-intl';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Login from '../common/Login';

import stylesLogin from '../../views/Login.css';
import { compose } from 'redux';
import Price from '../common/Price';
import List from '../util/List';
import IconLabel from '../util/IconLabel';
import StripePaymentForm from '../premium/StripePaymentForm';
import BackButton from '../common/BackButton';

import stylesSonosAuth from './SonosAuth.css';
import ConnectToSonosStep from './ConnectToSonosStep';
import BackToSonosStep from './BackToSonosStep';
import SonosStep from './SonosStep';
import {
  PLAN_ID_PREMIUM,
  SUBSCRIPTION_PRICE_EURO,
  SUBSCRIPTION_SONOS_TRIAL_DURATION_DAYS,
  SUBSCRIPTION_TRIAL_DURATION_DAYS,
} from '../../constants';
import { connect } from 'react-redux';
import { selectIsSonosFromId, selectUserIsAllowedOptOutTrial } from '../../selectors/user';

import SubscribeModalStep2Header from '../messages/SubscribeModalStep2Header';
import SubscribeModalPricePerMonthText from '../messages/SubscribeModalPricePerMonthText';
import SonosAuthLoginStep1HeaderSonosDeal from '../messages/SonosAuthLoginStep1HeaderSonosDeal';
import SonosAuthLoginStep1TextSonosDeal from '../messages/SonosAuthLoginStep1TextSonosDeal';
import SonosAuthLoginStep1PriceTextSonosDeal from '../messages/SonosAuthLoginStep1PriceTextSonosDeal';

const LOGIN_STEP = 1;
const PAYMENT_INITIAL = 2;
const PAYMENT_BENEFITS = 3;
const PAYMENT_FORM = 4;
const CONNECT_TO_SONOS = 5;
const BACK_TO_SONOS = 6;

const TOTAL_STEPS_NON_SUBSCRIBED = 5;
const TOTAL_STEPS_SUBSCRIBED = 2;

const messages = defineMessages({
  oldPremiumUserBenefit1: {
    id: 'subscribe-modal.argument.no-commitments',
    defaultMessage: 'No commitments',
  },
  oldPremiumUserBenefit2: {
    id: 'subscribe-modal.argument.cancel-online',
    defaultMessage: 'Cancel online anytime',
  },
  oldPremiumUserBenefit3: {
    id: 'subscribe-modal.argument.recurring-monthly',
    defaultMessage: 'Recurring monthly payments',
  },
});

export const benefitsList = [
  messages.oldPremiumUserBenefit1,
  messages.oldPremiumUserBenefit2,
  messages.oldPremiumUserBenefit3,
];

const getAnalyticsStepName = stepNumber => {
  switch (stepNumber) {
    case 1:
      return 'Login';
    case 2:
      return 'TrialOptOutInformation';
    case 3:
      return 'Subscribe';
    case 4:
      return 'PaymentDetails';
    case 5:
      return 'AuthorizeSonos';
    case 6:
      return 'AuthorizationCompleted';
    default:
      return 'Login';
  }
};

class SonosLogin extends Component {
  static propTypes = {
    getSonosAccessToken: PropTypes.func.isRequired,
    shouldShowPaymentInfo: PropTypes.bool.isRequired,
    linkCode: PropTypes.string.isRequired,
    authorizeSonos: PropTypes.func.isRequired,
    trackStepCompletion: PropTypes.func.isRequired,
    trackSuccessfulAuthorization: PropTypes.func.isRequired,
    trialDurationInMonths: PropTypes.number,
    userIsEligibleForSonosDeal: PropTypes.bool.isRequired,
    userIsAllowedOptOutTrial: PropTypes.bool.isRequired,
    intl: intlShape.isRequired,
  };

  static contextTypes = {
    router: PropTypes.object,
  };

  static defaultProps = {
    trialDurationInMonths: 2,
  };

  state = {
    step: LOGIN_STEP,
    accessToken: '',
  };

  onLoginSuccess = async loginData => {
    const { email, password } = loginData;
    const { shouldShowPaymentInfo } = this.props;

    const accessToken = await this.props.getSonosAccessToken(email, password);

    const nextStep = shouldShowPaymentInfo ? PAYMENT_INITIAL : CONNECT_TO_SONOS;

    this.props.trackStepCompletion(getAnalyticsStepName(this.state.step));
    this.setState({
      step: nextStep,
      accessToken,
    });
  };

  onAuthorizeSuccess = () => {
    this.props.trackSuccessfulAuthorization();
    this.setState({ step: this.state.step + 1 });
  };

  renderBenefitItem = (message, i) => {
    return (
      <li className={stylesSonosAuth.argument} key={i}>
        <IconLabel name="check" className={stylesSonosAuth.iconCheck} size="32px" />
        <span>{this.props.intl.formatMessage(message)}</span>
      </li>
    );
  };

  renderSwitchToSonosView() {
    const { shouldShowPaymentInfo } = this.props;

    const totalSteps = shouldShowPaymentInfo ? TOTAL_STEPS_NON_SUBSCRIBED : TOTAL_STEPS_SUBSCRIBED;
    const currentStep = shouldShowPaymentInfo ? 5 : 2;
    return <BackToSonosStep totalSteps={totalSteps} currentStep={currentStep} />;
  }

  renderLoginStep() {
    const classesView = classnames(stylesLogin.view, 'u-page-container');
    return (
      <div className={classesView}>
        <Login onAuthComplete={this.onLoginSuccess} hideSocialOptions />
      </div>
    );
  }

  renderPaymentInitialSonosDeal() {
    const { trialDurationInMonths } = this.props;

    return (
      <SonosStep
        currentStep={1}
        totalSteps={TOTAL_STEPS_NON_SUBSCRIBED}
        onButtonClick={this.increaseStep}
        iconName={'select'}
      >
        <div className={stylesSonosAuth.heading}>
          <h2>
            <SonosAuthLoginStep1HeaderSonosDeal trialDurationInMonths={trialDurationInMonths} />
          </h2>
          <p className={stylesSonosAuth.subheading}>
            <SonosAuthLoginStep1TextSonosDeal />
          </p>
        </div>
        <div className={stylesSonosAuth.priceBox}>
          <div className={stylesSonosAuth.priceText}>
            <SonosAuthLoginStep1PriceTextSonosDeal trialDurationInMonths={trialDurationInMonths} />
          </div>
          <div>
            <span className={stylesSonosAuth.price}>
              <Price price={1.0} />
            </span>
          </div>
        </div>
      </SonosStep>
    );
  }

  renderPaymentInitialTrialOptIn() {
    return (
      <SonosStep
        currentStep={1}
        totalSteps={TOTAL_STEPS_NON_SUBSCRIBED}
        onButtonClick={this.increaseStep}
        iconName={'select'}
      >
        <div className={stylesSonosAuth.heading}>
          <h2>
            <FormattedMessage
              id="sonos-auth.login-step-1.header"
              defaultMessage="Start your free {trialDuration} days"
              values={{ trialDuration: SUBSCRIPTION_TRIAL_DURATION_DAYS }}
            />
          </h2>
          <p className={stylesSonosAuth.subheading}>
            <FormattedMessage
              id="sonos-auth.login-step-1.text"
              defaultMessage="Try all of the IDAGIO features free for {trialDuration} days. You won't be charged until after your {trialDuration} days free trial ends. No commitment, cancel anytime."
              values={{ trialDuration: SUBSCRIPTION_TRIAL_DURATION_DAYS }}
            />
          </p>
        </div>
        <div className={stylesSonosAuth.priceBox}>
          <div className={stylesSonosAuth.priceText}>
            <FormattedMessage
              id="sonos-auth.login-step-1.price-text"
              defaultMessage="{trialDuration} days free, then"
              values={{ trialDuration: SUBSCRIPTION_TRIAL_DURATION_DAYS }}
            />
          </div>
          <div>
            <span className={stylesSonosAuth.price}>
              <Price />
            </span>
            <span className={stylesSonosAuth.priceMonthly}>
              <SubscribeModalPricePerMonthText />
            </span>
          </div>
        </div>
      </SonosStep>
    );
  }

  renderPaymentInitialExpiredTrialOptIn() {
    return (
      <SonosStep
        currentStep={1}
        totalSteps={TOTAL_STEPS_NON_SUBSCRIBED}
        onButtonClick={this.increaseStep}
        iconName={'select'}
      >
        <div className={stylesSonosAuth.heading}>
          <h2>
            <FormattedMessage
              id="sonos-auth.login-step-1.header-expired-trial"
              defaultMessage="Please upgrade your IDAGIO account"
            />
          </h2>
          <p className={stylesSonosAuth.subheading}>
            <FormattedMessage
              id="sonos-auth.login-step-1.upgrade-account"
              defaultMessage="In order to use IDAGIO with your Sonos device, you need to have an active IDAGIO subscription."
            />
          </p>
          <p className={stylesSonosAuth.subheading}>
            <FormattedMessage
              id="sonos-auth.login-step-1.text-expired-trial"
              defaultMessage="Subscribe now to expand your collection with access to over 600 of the world’s best classical labels, offline playback and lossless audio quality. No commitment, cancel anytime."
            />
          </p>
        </div>
        <div className={stylesSonosAuth.priceBox}>
          <div>
            <span className={stylesSonosAuth.price}>
              <Price />
            </span>
            <span className={stylesSonosAuth.priceMonthly}>
              <SubscribeModalPricePerMonthText />
            </span>
          </div>
        </div>
      </SonosStep>
    );
  }

  renderPaymentInitial() {
    const { userIsAllowedOptOutTrial, userIsEligibleForSonosDeal } = this.props;

    if (userIsEligibleForSonosDeal) {
      return this.renderPaymentInitialSonosDeal();
    }

    if (!userIsAllowedOptOutTrial) {
      return this.renderPaymentInitialExpiredTrialOptIn();
    }

    return this.renderPaymentInitialTrialOptIn();
  }

  renderPaymentBenefits() {
    return (
      <SonosStep
        currentStep={2}
        totalSteps={TOTAL_STEPS_NON_SUBSCRIBED}
        onButtonClick={this.increaseStep}
        iconName="subscribe-lock"
      >
        <h2>
          <SubscribeModalStep2Header />
        </h2>
        <List
          className={stylesSonosAuth.listArguments}
          items={benefitsList}
          renderItem={this.renderBenefitItem}
        />
      </SonosStep>
    );
  }

  renderPaymentForm() {
    const { shouldShowPaymentInfo, userIsAllowedOptOutTrial, userIsEligibleForSonosDeal } =
      this.props;

    const totalSteps = shouldShowPaymentInfo ? TOTAL_STEPS_NON_SUBSCRIBED : TOTAL_STEPS_SUBSCRIBED;
    const currentStep = shouldShowPaymentInfo ? 3 : 1;
    let price = 0;
    let trialDuration = SUBSCRIPTION_TRIAL_DURATION_DAYS;

    if (userIsEligibleForSonosDeal) {
      price = 1.0;
      trialDuration = SUBSCRIPTION_SONOS_TRIAL_DURATION_DAYS;
    }

    if (!userIsAllowedOptOutTrial) {
      price = SUBSCRIPTION_PRICE_EURO;
    }

    return (
      <SonosStep
        currentStep={currentStep}
        totalSteps={totalSteps}
        shouldRenderButton={false}
        iconName="subscribe-lock"
      >
        <StripePaymentForm
          trialDuration={trialDuration}
          price={price}
          isInModal={false}
          onSuccess={this.increaseStep}
          selectedPlan={PLAN_ID_PREMIUM}
          userIsAllowedOptOutTrial={this.props.userIsAllowedOptOutTrial}
        />
      </SonosStep>
    );
  }

  renderConnectToSonosStep() {
    const { linkCode, authorizeSonos, shouldShowPaymentInfo } = this.props;
    const { accessToken } = this.state;

    const totalSteps = shouldShowPaymentInfo ? TOTAL_STEPS_NON_SUBSCRIBED : TOTAL_STEPS_SUBSCRIBED;
    const currentStep = shouldShowPaymentInfo ? 4 : 1;

    return (
      <ConnectToSonosStep
        totalSteps={totalSteps}
        currentStep={currentStep}
        linkCode={linkCode}
        accessToken={accessToken}
        authorizeSonos={authorizeSonos}
        onAuthorize={this.onAuthorizeSuccess}
      />
    );
  }

  render() {
    const { step } = this.state;
    const backButtonVisible = step > LOGIN_STEP && step < CONNECT_TO_SONOS;
    return (
      <div>
        <BackButton
          onClick={this.decreaseStep}
          isActive={backButtonVisible}
          isVisible={backButtonVisible}
        />

        <div className={stylesSonosAuth.container}>
          {this.state.step === LOGIN_STEP && this.renderLoginStep()}
          {this.state.step === PAYMENT_INITIAL && this.renderPaymentInitial()}
          {this.state.step === PAYMENT_BENEFITS && this.renderPaymentBenefits()}
          {this.state.step === PAYMENT_FORM && this.renderPaymentForm()}
          {this.state.step === CONNECT_TO_SONOS && this.renderConnectToSonosStep()}
          {this.state.step === BACK_TO_SONOS && this.renderSwitchToSonosView()}
        </div>
      </div>
    );
  }

  increaseStep = () => {
    this.props.trackStepCompletion(getAnalyticsStepName(this.state.step));
    this.setState({ step: this.state.step + 1 });
  };

  decreaseStep = () => {
    this.setState({ step: this.state.step <= 1 ? 1 : this.state.step - 1 });
  };
}

function mapStateToProps(state) {
  return {
    userIsEligibleForSonosDeal: selectIsSonosFromId(state),
    userIsAllowedOptOutTrial: selectUserIsAllowedOptOutTrial(state),
  };
}

export default compose(injectIntl, connect(mapStateToProps, {}))(SonosLogin);
