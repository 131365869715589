import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router';

import { selectIsInApp } from '../../selectors/client';
import * as uiActions from '../../actions/ui';

import { NavigationPlugin } from '../../capacitor-connector';

const ContentfulLink = ({ node, children, showModal, inApp, fromId }) => {
  const showSubscribeModal = () => {
    showModal('SUBSCRIBE_MODAL', { trigger: fromId });
  };

  const uri = node.data.uri;
  if (uri.startsWith('https://app.idagio.com')) {
    const to = uri.replace('https://app.idagio.com', '');

    if (to.endsWith('/subscribe') && !inApp) {
      return (
        <a className="c-text-link--is-visible" href="#" onClick={showSubscribeModal}>
          {children}
        </a>
      );
    }

    return (
      <Link onlyActiveOnIndex={false} to={to}>
        {children}
      </Link>
    );
  }

  // Sometimes mail links come mailto: links, sometimes as plain email
  // addresses, this handles both
  if (/\S+@\S+\.\S+/.test(uri) || uri.startsWith('mailto:')) {
    let emailAddress = uri;

    if (emailAddress.startsWith('mailto:')) {
      emailAddress = emailAddress.split('mailto:')[1];
    }

    const handleClick = e => {
      if (__CAPACITOR__) {
        e.preventDefault();
        NavigationPlugin.androidShouldOpenMailApp({ receiverAddress: emailAddress });
      }
    };

    return (
      <a
        className="c-text-link--is-visible"
        href={`mailto:${emailAddress}`}
        target="_blank"
        rel="noopener noreferrer"
        onClick={handleClick}
      >
        {children}
      </a>
    );
  }

  return (
    <a className="c-text-link--is-visible" href={uri} target="_blank" rel="noopener noreferrer">
      {children}
    </a>
  );
};

ContentfulLink.propTypes = {
  node: PropTypes.object,
  children: PropTypes.node,
  showModal: PropTypes.func,
  inApp: PropTypes.bool,
  fromId: PropTypes.string,
};

function mapStateToProps(state) {
  return {
    inApp: selectIsInApp(state),
  };
}

export default connect(mapStateToProps, {
  showModal: uiActions.showModal,
})(ContentfulLink);
