import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Link, withRouter, locationShape } from 'react-router';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classnames from 'classnames';
import { defineMessages, FormattedMessage, injectIntl, intlShape } from 'react-intl';
import IconLabel from './../util/IconLabel';
import enhanceWithClickOutside from 'react-click-outside';
import styles from './MainMenu.css';
import * as analyticsActions from '../../actions/analytics';

import GiftCardLink from './GiftCardLink';
import CollectionLinks from '../common/CollectionLinks';

import AccountJoin from '../messages/AccountJoin';
import AccountLogin from '../messages/AccountLogin';
import MenuDiscover from '../messages/MenuDiscover';
import MenuBrowse from '../messages/MenuBrowse';
import MenuMood from '../messages/MenuMood';
import MenuRecentlyPlayed from '../messages/MenuRecentlyPlayed';
import { selectLocale } from '../../selectors/client';

import { LANGUAGE_SUPPORT_LINKS, CONSTANT_MESSAGES } from '../../constants';
import { splitLocale, getWebsiteUrlInLocale } from '../../lib/locale-utils';
import MenuConcertsAndCourses from '../messages/MenuConcertsAndCourses';

const messages = defineMessages({
  aboutLink: {
    id: 'menu.about',
    defaultMessage: 'About IDAGIO',
  },
  supportLink: {
    id: 'menu.help',
    defaultMessage: 'Help & Contact',
  },
});

class MainMenu extends PureComponent {
  static propTypes = {
    isAuthenticated: PropTypes.bool.isRequired,
    isMac: PropTypes.bool.isRequired,
    isWindows: PropTypes.bool.isRequired,
    logout: PropTypes.func.isRequired,
    className: PropTypes.string,
    track: PropTypes.func.isRequired,
    locale: PropTypes.string.isRequired,
    intl: intlShape,
    location: locationShape.isRequired,
  };

  state = {
    menuIsOpen: false,
  };

  renderAboutLink() {
    const { intl, locale } = this.props;
    const text = intl.formatMessage(messages.aboutLink);
    const ariaLink = intl.formatMessage(CONSTANT_MESSAGES.externalLink, { linkText: text });

    return (
      <li key="about">
        <a
          href={getWebsiteUrlInLocale('/about', locale)}
          className={styles.link}
          target="_blank"
          rel="noopener noreferrer"
          aria-label={ariaLink}
          data-test="main-menu-about-link"
          onClick={this.closeMenu}
        >
          {text}
        </a>
      </li>
    );
  }

  renderStaticMenu() {
    const { locale, intl } = this.props;

    const supportLink = LANGUAGE_SUPPORT_LINKS[splitLocale(locale).language];
    const text = intl.formatMessage(messages.supportLink);
    const ariaLink = intl.formatMessage(CONSTANT_MESSAGES.externalLink, { linkText: text });

    return (
      <ul>
        <li key="discover">
          <Link
            to="/discover"
            className={styles.linkSmallView}
            onlyActiveOnIndex={false}
            onClick={this.closeMenu}
            data-test="main-menu-discover-link"
          >
            <MenuDiscover />
          </Link>
        </li>
        <li key="browse">
          <Link
            to="/browse"
            className={styles.linkSmallView}
            onlyActiveOnIndex={false}
            onClick={this.closeMenu}
            data-test="main-menu-browse-link"
          >
            <MenuBrowse />
          </Link>
        </li>
        <li key="moods">
          <Link
            to="/moods"
            className={styles.linkSmallView}
            onlyActiveOnIndex={false}
            onClick={this.closeMenu}
            data-test="main-menu-moods-link"
          >
            <MenuMood />
          </Link>
        </li>
        <li key="live">
          <Link
            to="/live"
            className={styles.linkSmallView}
            onlyActiveOnIndex={false}
            onClick={this.closeMenu}
            data-test="main-menu-live-link"
          >
            <MenuConcertsAndCourses />
          </Link>
        </li>
        <li key="recently-played">
          <Link
            to="/recently-played"
            className={styles.linkSmallView}
            onlyActiveOnIndex={false}
            onClick={this.closeMenu}
            data-test="main-menu-recently-played-link"
          >
            <MenuRecentlyPlayed />
          </Link>
        </li>
        <li key="help">
          <a
            href={supportLink}
            className={styles.link}
            target="_blank"
            rel="noopener noreferrer"
            aria-label={ariaLink}
            onClick={this.closeMenu}
            data-test="main-menu-help-link"
          >
            {text}
          </a>
        </li>
        {!__ELECTRON__ && this.renderAboutLink()}
      </ul>
    );
  }

  renderUserMenu() {
    const { isAuthenticated, isMac, isWindows, locale, location } = this.props;
    if (isAuthenticated) {
      return (
        <ul>
          <li>
            <Link
              to="/account"
              className={styles.link}
              onlyActiveOnIndex={false}
              onClick={this.closeMenu}
            >
              <FormattedMessage id="menu.settings" defaultMessage="Settings" />
            </Link>
          </li>
          <li>
            <button className={styles.link} onClick={this.props.logout}>
              <FormattedMessage id="account.logout" defaultMessage="Log Out" />
            </button>
          </li>
          {!__ELECTRON__ && (isMac || isWindows) && (
            <li>
              <Link
                to="/install"
                className={styles.link}
                onClick={this.trackDownloadDesktop}
                onlyActiveOnIndex={false}
              >
                <FormattedMessage id="menu.download-app" defaultMessage="Download App" />
              </Link>
            </li>
          )}
          <GiftCardLink locale={locale} onClick={this.closeMenu} />
        </ul>
      );
    }

    const to = `/${location.pathname}${location.search}`;

    return (
      <ul>
        <li>
          <Link
            to={`/login?to=${encodeURIComponent(to)}`}
            className={styles.linkSmallMediumView}
            onlyActiveOnIndex={false}
            data-test="main-menu-login-link"
          >
            <AccountLogin />
          </Link>
        </li>
        <li>
          <Link
            to={`/join?to=${encodeURIComponent(to)}`}
            className={styles.linkSmallMediumView}
            onlyActiveOnIndex={false}
            data-test="main-menu-join-link"
          >
            <AccountJoin />
          </Link>
        </li>
        <GiftCardLink locale={locale} onClick={this.closeMenu} />
      </ul>
    );
  }

  render() {
    const mainMenuClassNames = classnames(styles.component, this.props.className, {
      'is-expanded': this.state.menuIsOpen,
    });
    return (
      <div className={mainMenuClassNames}>
        <button className={styles.toggle} onClick={this.toggleMenu} data-test="main-menu-toggle">
          <IconLabel name="menu" className={styles.icon} title="Menu" />
        </button>
        <div className={styles.list}>
          {this.renderStaticMenu()}
          {this.props.isAuthenticated && (
            <CollectionLinks linkClassName={styles.linkSmallView} onClick={this.closeMenu} />
          )}
          {this.renderUserMenu()}
        </div>
      </div>
    );
  }

  trackDownloadDesktop = () => {
    this.props.track('Clicked Download Desktop App', { page: 'MainMenu' });
  };

  toggleMenu = () => this.setState(prevState => ({ menuIsOpen: !prevState.menuIsOpen }));

  closeMenu = () => this.setState({ menuIsOpen: false });

  handleClickOutside = () => this.closeMenu();
}

function mapStateToProps(state) {
  return {
    locale: selectLocale(state),
  };
}

export default compose(
  injectIntl,
  connect(mapStateToProps, { track: analyticsActions.track }),
  enhanceWithClickOutside,
  withRouter
)(MainMenu);
