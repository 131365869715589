import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { ENTITY_TYPE_ARTIST } from '../../constants';

import * as Shapes from '../../shapes';

import ImageCredit from '../common/ImageCredit';
import ArtistAvatar from '../common/ArtistAvatar';
import CollectionButton from '../common/CollectionButton';
import ArtistFunctions from '../common/ArtistFunctions';
import PlayRadioButton from '../common/PlayRadioButton';

import styles from './ProfileHead.css';
import ShareButton from '../common/ShareButton';
import { compose } from 'redux';
import { injectIntl, intlShape } from 'react-intl';
import shareableEntity, { shareableEntityPropTypes } from '../../hoc/shareableEntity';
import { messages } from './messages';

class ProfileHead extends PureComponent {
  static propTypes = {
    profile: Shapes.Profile.isRequired,
    imageAnnotation: PropTypes.object,
    isInCollection: PropTypes.bool,
    toggleIsInCollection: PropTypes.func,
    intl: intlShape,

    ...shareableEntityPropTypes,
  };

  getProfileType = () => {
    const { profile } = this.props;
    return profile.numRecordingsAsComposer > profile.numRecordingsAsPerformer
      ? 'composer'
      : 'artist';
  };

  render() {
    const { profile, imageAnnotation, isInCollection, toggleIsInCollection, showShareModal, intl } =
      this.props;

    const radioTrackingContext = {
      contextType: 'radioArtist',
      contextId: profile.id.toString(),
    };

    return (
      <div className={styles.header} data-test="profile-head">
        <ArtistAvatar profileId={profile.id} className={styles.img} />
        <div>
          <h1 className={styles.name} data-test="profile-head.name">
            {profile.name}
          </h1>
          <ArtistFunctions functions={profile.functions} className={styles.functions} />
          <div className={styles.headerBtns}>
            <PlayRadioButton
              type={this.getProfileType()}
              id={profile.id}
              trackingContext={radioTrackingContext}
              data-test="profile-header.play-radio-btn"
            />
            {__CAPACITOR__ ? null /* in capacitor we display the button in a fixed header bar */ : (
              <React.Fragment>
                <CollectionButton active={isInCollection} onClick={toggleIsInCollection} />
                <ShareButton onClick={showShareModal} text={intl.formatMessage(messages.share)} />
              </React.Fragment>
            )}
          </div>
          <ImageCredit className={styles.imgCredit} annotation={imageAnnotation} />
        </div>
      </div>
    );
  }
}

function getShareUrl({ profile }) {
  return `/profiles/${profile.slug}/`;
}

function getShareTrackingContext(props) {
  return {
    sharedContentType: 'Artist',
    sharedContentId: props.profile.id,
    contextType: 'Profile',
    contentId: props.profile.id,
  };
}

export default compose(
  shareableEntity(getShareUrl, getShareTrackingContext, ENTITY_TYPE_ARTIST, {
    sharedUrlParams: {
      utm_medium: 'profile-share',
    },
  }),
  injectIntl
)(ProfileHead);
