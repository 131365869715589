// @flow
import React from 'react';
import {
  COURSE_RESOURCE_TYPE,
  CONTENT_TYPE,
  LIVESTREAM_EVENT_IMAGE_DIMS,
  PLAYABLE_ITEM_PREVIEW_DIMS,
} from '../../constants';
import DownloadButton from '../common/DownloadButton';
import { connect } from 'react-redux';
import classNames from 'classnames';
import styles from './CourseResource.css';
import * as uiActions from '../../actions/ui';
import { FormattedMessage } from 'react-intl';
import type { CourseResource as CourseResourceProps } from '../../shapes/types';

declare var __CAPACITOR__: boolean;

type OwnProps = {
  item: CourseResourceProps,
  resourceType: string,
};

type DispatchProps = {
  showModal: Function,
};

type Props = OwnProps & DispatchProps;

const BG_STYLE = {
  [CONTENT_TYPE.IMAGE]: [styles.defaultBg],
  [CONTENT_TYPE.VIDEO]: [styles.videoBg],
  [CONTENT_TYPE.AUDIO]: [styles.defaultBg, styles.audioBg],
  [CONTENT_TYPE.PDF]: [styles.defaultBg, styles.pdfBg],
  [CONTENT_TYPE.PRESENTATION]: [styles.defaultBg, styles.presentationBg],
};

const CourseResource = ({ item, resourceType, showModal }: Props) => {
  const { title, description, asset, thumbnailUrl, contentType } = item;
  const assetUrl = asset.file.url;

  const isImage = resourceType === COURSE_RESOURCE_TYPE.IMAGE;
  const isVideo = resourceType === COURSE_RESOURCE_TYPE.VIDEO;

  const dims = isVideo ? LIVESTREAM_EVENT_IMAGE_DIMS : PLAYABLE_ITEM_PREVIEW_DIMS;
  const imgSuffix = `?fm=jpg&fl=progressive&fit=thumb&f=center&h=${dims.h}&w=${dims.w}`;

  const defaultThumbnailUrl = isImage ? assetUrl : '';
  const thumbnail = thumbnailUrl || defaultThumbnailUrl;

  const isClickable = isImage && !__CAPACITOR__;

  const onClickThumbnail = () => {
    showModal('COURSE_RESOURCE_PREVIEW_MODAL', null, {
      courseResource: {
        thumbnail,
        assetUrl,
        title,
        description,
      },
    });
  };

  const Thumbnail = () => (
    <figure role="none" className={classNames(styles.figure, styles.bg, BG_STYLE[contentType])}>
      {thumbnail && <img src={thumbnail + imgSuffix} role="img" className={styles.image} />}
    </figure>
  );

  return (
    <div className={isVideo ? styles.videoComponent : styles.component}>
      {isClickable ? (
        <button className={styles.thumbnail} onClick={onClickThumbnail}>
          <Thumbnail />
          <div className={styles.overlay}>
            <div>
              <FormattedMessage id="course-resource.image.view.text" defaultMessage="View Image" />
            </div>
          </div>
        </button>
      ) : (
        <div className={styles.thumbnail}>
          <Thumbnail />
        </div>
      )}
      <div className={styles.info}>
        <DownloadButton url={assetUrl} />
        <span className={styles.title}>{title}</span>
        {description && <span className={styles.description}>{description}</span>}
      </div>
    </div>
  );
};

const dispatchProps: DispatchProps = {
  showModal: uiActions.showModal,
};

export default connect(null, dispatchProps)(CourseResource);
