import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import dataComponent from '../hoc/dataComponent';
import { Link } from 'react-router';
import { defineMessages, intlShape, injectIntl } from 'react-intl';
import CapacitorHeaderBar from '../components/capacitor/HeaderBar';

import { loadComposers } from '../actions/composer';
import { selectComposers } from '../selectors/categories';
import CapacitorRipple from '../components/capacitor/Ripple';
import styles from './GenericList.css';

import {
  selectComposersIsLoading,
  selectNextComposersCursor,
  selectPrevComposersCursor,
} from '../selectors/composer';

import * as Shapes from '../shapes';

import Head from '../components/chrome/Head';
import List from '../components/util/List';
import PaginatedList from '../components/util/PaginatedList';

const messages = defineMessages({
  metaTitle: {
    id: 'composers.meta.title',
    defaultMessage: 'Composers',
  },
  metaDescription: {
    id: 'composers.meta.description',
    defaultMessage:
      'Find and play curated classical music from hundreds of composers. Compare alternative recordings and browse by performer.',
  },
});

class Composers extends Component {
  static propTypes = {
    items: PropTypes.arrayOf(Shapes.Person).isRequired,
    intl: intlShape,
    currentCursor: PropTypes.string,
    nextCursor: PropTypes.string,
    prevCursor: PropTypes.string,
    loadComposers: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    location: PropTypes.object.isRequired,
  };

  renderItem(composer) {
    return (
      <li key={composer.id}>
        <Link to={`/profiles/${composer.id}`} className={styles.link}>
          <CapacitorRipple />
          <strong>{composer.surname}</strong>, {composer.forename}
        </Link>
      </li>
    );
  }

  render() {
    const { intl, items, currentCursor, nextCursor, prevCursor, isLoading, location } = this.props;
    const { formatMessage } = intl;
    const title = formatMessage(messages.metaTitle);
    return (
      <div className="u-page-container" data-test="composers-page">
        <Head
          title={title}
          description={formatMessage(messages.metaDescription)}
          currentCursor={currentCursor}
          nextCursor={nextCursor}
          prevCursor={prevCursor}
        />
        {__CAPACITOR__ && (
          <CapacitorHeaderBar
            shareContentType="Capacitor"
            title={title}
            onlyShowTitleOnScroll
            showSearch
          />
        )}
        <h1 className="fz--beta">{title}</h1>
        <PaginatedList
          isLoading={isLoading}
          currentCursor={currentCursor}
          nextCursor={nextCursor}
          prevCursor={prevCursor}
          location={location}
          loadMore={this.loadMore}
        >
          <List items={items} renderItem={this.renderItem} />
        </PaginatedList>
      </div>
    );
  }

  loadMore = () => {
    const { nextCursor, isLoading } = this.props;
    if (!isLoading && nextCursor) {
      this.props.loadComposers(nextCursor);
    }
  };
}

function fetchData(store, { cursor }) {
  return store.dispatch(loadComposers(cursor));
}

function mapStateToProps(state, ownProps) {
  return {
    items: selectComposers(state),
    currentCursor: ownProps.params.cursor,
    nextCursor: selectNextComposersCursor(state),
    prevCursor: selectPrevComposersCursor(state),
    isLoading: selectComposersIsLoading(state),
    location: ownProps.location,
  };
}

export default compose(
  dataComponent(fetchData),
  connect(mapStateToProps, { loadComposers }),
  injectIntl
)(Composers);
