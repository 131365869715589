// @flow
// $FlowFixMe
import React, { useCallback, useState, useEffect } from 'react';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import ToggleSwitch from '../util/ToggleSwitch';
import { DownloadPlugin } from '../../capacitor-connector';
import { downloadUpdateAction } from '../../actions/download';
import { addToCollection as addToCollectionAction } from '../../actions/collection';
import { selectEntityDownloadInfo } from '../../selectors/download';
import { selectEntityIsInCollection } from '../../selectors/collection';
import { selectUserIsPatron } from '../../selectors/user';
import { showUpsellView as showUpsellViewAction } from '../../actions/capacitor';
import styles from './DownloadBar.css';

type DownloadMessageProps = {
  internalDownloadStatus: string,
  downloadedTracksLength: number,
  totalTracksLength: number,
};

const DownloadMessage = ({
  internalDownloadStatus,
  downloadedTracksLength,
  totalTracksLength,
}: DownloadMessageProps) => {
  if (internalDownloadStatus === 'download_running') {
    return (
      <FormattedMessage
        id="download.downloading"
        defaultMessage="Downloading {downloadedLength}/{totalLength}..."
        values={{
          downloadedLength: downloadedTracksLength,
          totalLength: totalTracksLength,
        }}
      />
    );
  }
  if (internalDownloadStatus === 'downloaded') {
    return <FormattedMessage id="download.is-downloaded" defaultMessage="Downloaded" />;
  }
  return <FormattedMessage id="download.start-download" defaultMessage="Download" />;
};

type OwnProps = {
  entityType: string,
  entityIdOrSlug: string | number,
  tracksLength: number,
};

type MapStateToProps = {
  downloadInfo?: {
    downloadStatus: string,
    downloadedTrackIds?: Array<string>,
  },
  userIsPatron: boolean,
  isInCollection: boolean,
};

type DispatchProps = {
  downloadUpdate: Function,
  addToCollection: Function,
  showUpsellView: Function,
};

type Props = OwnProps & MapStateToProps & DispatchProps;

const DownloadBar = ({
  entityType,
  entityIdOrSlug,
  downloadUpdate,
  downloadInfo,
  userIsPatron,
  showUpsellView,
  addToCollection,
  isInCollection,
  tracksLength,
}: Props) => {
  const isLoadingInfo = typeof downloadInfo === 'undefined';
  const { downloadStatus, downloadedTrackIds } = downloadInfo || {};
  // We want optimistic UI updates, so we have an internal state, but sync it
  // up to what we get from the store if it should change.
  const [internalDownloadStatus, setInternalDownloadStatus] = useState(downloadStatus);
  const [internalDownloadedTrackIds, setInternalDownloadedTrackIds] = useState([]);

  const handleDownloadUpdate = useCallback(
    ({ entityIdOrSlug: entityId, downloadStatus: status, downloadedTrackIds: trackIds }) => {
      if (!entityId) {
        return;
      }

      // Android can emit a status that matched the previous state (downloaded or download_running)
      // When this state is received, the toggle needs to be set back to ON
      if (status === 'downloaded' || status === 'download_running') {
        setInternalDownloadStatus(status);
      }

      downloadUpdate({
        entityId,
        downloadStatus: status,
        downloadedTrackIds: trackIds && trackIds.length > 0 ? trackIds : undefined,
      });
    },
    [downloadUpdate]
  );

  useEffect(() => {
    if (downloadStatus) {
      setInternalDownloadStatus(downloadStatus);
    }
    if (downloadedTrackIds && downloadedTrackIds.length > 0) {
      setInternalDownloadedTrackIds(downloadedTrackIds);
    }
  }, [downloadStatus, downloadedTrackIds]);

  useEffect(() => {
    DownloadPlugin.subscribe(
      {
        entityType: entityType,
        entityIdOrSlug: entityIdOrSlug.toString(),
      },
      handleDownloadUpdate
    );
    return () => {
      DownloadPlugin.unsubscribe();
    };
  }, [entityIdOrSlug, entityType, handleDownloadUpdate]);

  const handleChange = e => {
    if (!userIsPatron) {
      e.preventDefault();
      showUpsellView('fromDownloadToggle');
      return;
    }
    if (e.target.checked) {
      setInternalDownloadStatus('download_running');
      if (!isInCollection && entityType !== 'personal_playlist') {
        addToCollection(entityType, [entityIdOrSlug]);
      }
      DownloadPlugin.downloadSubscribedEntity();
    } else {
      setInternalDownloadStatus('not_downloading');
      DownloadPlugin.removeDownloadForSubscribedEntity();
    }
  };

  const switchIsActive =
    internalDownloadStatus === 'download_running' || internalDownloadStatus === 'downloaded';

  return (
    <div className={classnames(styles.downloadBar, { [styles.isLoading]: isLoadingInfo })}>
      {!isLoadingInfo && (
        <React.Fragment>
          <DownloadMessage
            internalDownloadStatus={internalDownloadStatus || 'not_downloading'}
            downloadedTracksLength={internalDownloadedTrackIds.length}
            totalTracksLength={tracksLength}
          />
          <ToggleSwitch
            checked={switchIsActive}
            onChange={handleChange}
            id="download"
            label="Download"
            name="download"
          />
        </React.Fragment>
      )}
    </div>
  );
};

function mapStateToProps(state: Object, ownProps: OwnProps): MapStateToProps {
  const collectionIdsState = state.lists.collection.ids;
  return {
    downloadInfo: selectEntityDownloadInfo(state, ownProps.entityIdOrSlug),
    userIsPatron: selectUserIsPatron(state),
    isInCollection: selectEntityIsInCollection(
      ownProps.entityType,
      collectionIdsState,
      ownProps.entityIdOrSlug
    ),
  };
}

const dispatchProps: DispatchProps = {
  downloadUpdate: downloadUpdateAction,
  addToCollection: addToCollectionAction,
  showUpsellView: showUpsellViewAction,
};

export default connect(mapStateToProps, dispatchProps)(DownloadBar);
