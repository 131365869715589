import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import Button from './Button';
import { FormattedMessage } from 'react-intl';

export default class InstallButton extends PureComponent {
  static propTypes = {
    isMobile: PropTypes.bool,
  };
  render() {
    const { isMobile, children } = this.props;

    const link = isMobile
      ? 'https://idagio.onelink.me/0uza/redirect'
      : '/discover?showSubscribeModal=true';

    let text = <FormattedMessage id="landing-page-us.button-desktop" defaultMessage="Try Now" />;

    if (children) {
      text = children;
    }

    if (isMobile) {
      text = <FormattedMessage id="landing-page-us.button-mobile" defaultMessage="Install App" />;
    }

    return (
      <Button {...this.props} link={link}>
        {text}
      </Button>
    );
  }
}
