// @flow
import React from 'react';
import OverlaySlideUp from './OverlaySlideUp';
import { FormattedMessage } from 'react-intl';
import ResetAllActiveFacetsButton from '../facet/ResetAllActiveFacetsButton';
import List from '../util/List';
import FacetGroupMenuTrigger from '../facet/FacetGroupMenuTrigger';
import styles from '../facet/FacetGroups.css';

type OwnProps = {
  isVisible: boolean,
  onClose: Function,
  facets: Array<any>,
  location: Object,
  loadAndShowAllFacets: Function,
  getTriggerText: Function,
  shouldRenderFacetGroup: Function,
  activeFacets: Array<any>,
  onClickClear?: Function,
};

const FacetGroupsOverlay = ({
  isVisible,
  onClose,
  facets,
  loadAndShowAllFacets,
  getTriggerText,
  shouldRenderFacetGroup,
  location,
  activeFacets,
  onClickClear,
}: OwnProps) => {
  const { pathname, query } = location;

  const ResetButton = () => {
    if (!activeFacets.length) {
      return null;
    }

    return (
      <ResetAllActiveFacetsButton pathname={pathname} query={query} onClickClear={onClickClear} />
    );
  };

  const FacetGroup = (facetInfo: Object) => {
    if (!shouldRenderFacetGroup(facetInfo)) {
      return null;
    }

    const { type } = facetInfo;

    return (
      <li className={styles.item} key={type}>
        <FacetGroupMenuTrigger
          getTriggerText={getTriggerText}
          type={type}
          loadAndShowAllFacets={loadAndShowAllFacets}
        />
      </li>
    );
  };

  return (
    <OverlaySlideUp
      isVisible={isVisible}
      onClose={onClose}
      title={<FormattedMessage id="facet.groups.title" defaultMessage="Filters" />}
      navigation={<ResetButton />}
      data-test="facet.groups.overlay"
    >
      <List items={facets} renderItem={FacetGroup} className={styles.list} />
    </OverlaySlideUp>
  );
};

export default FacetGroupsOverlay;
